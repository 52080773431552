import React from 'react';
import App from './App';
import * as serviceWorker from './serviceWorker';


import './index.scss';
import {toast} from 'react-toastify';
import { hydrate, render } from "react-dom";
import { CometChat } from "@cometchat-pro/chat";


// Call it once in your app. At the root of your app is the best place
toast.configure({
    autoClose: 3500,
    hideProgressBar: true,
    bodyClassName: 'p-2'
});

const rootElement = document.getElementById("root");

// Initialize CometChat

const appID = process.env.REACT_APP_COMETCHAT_APP_ID;
const region = "us";
const appSetting = new CometChat.AppSettingsBuilder().subscribePresenceForAllUsers().setRegion(region).build();
CometChat.init(appID, appSetting).then(
  () => {
    console.log("Initialization completed successfully");
    // You can now call login function.
  },
  error => {
    console.log("Initialization failed with error:", error);
    // Check the reason for error and take appropriate action.
  }
);
// if (window.location.hostname === 'villagewellth.com' || window.location.hostname === 'www.villagewellth.com') {
// FullStory.init({ orgId: 'ZS2JH',
// namespace: 'FullStory' });
// console.log('FullStory initialized')
// } else {
//   console.log('FullStory not initialized')
//   console.log(window.location.hostname)
// }



const APP = (<React.StrictMode>
  <App />
</React.StrictMode>)

if (rootElement.hasChildNodes()) {
  hydrate(APP, rootElement);
} else {
  render(APP, rootElement);
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
