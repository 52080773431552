import React, { createContext, useState } from "react";

const NotificationsContext = createContext();

const NotificationsProvider = ({ children }) => {
  const [unreadMessages, setUnreadMessages] = useState([]);
  const [userConnectionRequests, setUserConnectionRequests] = useState(false);
  const [fetchUnreadMessages, setFetchUnreadMessages] = useState(false);
  const [activeConversation, setActiveConversation] = useState(null);
  const [fetchConnectionRequests, setFetchConnectionRequests] = useState(false);
  const [userLoggedInChat, setUserLoggedInChat] = useState(false);
  const [stripeCustomer, setStripeCustomer] = useState(null);
  const [activeSubscription, setActiveSubscription] = useState(null);

  return (
    <NotificationsContext.Provider
      value={{
        unreadMessages,
        setUnreadMessages,
        userConnectionRequests,
        setUserConnectionRequests,
        fetchUnreadMessages,
        setFetchUnreadMessages,
        activeConversation,
        setActiveConversation,
        fetchConnectionRequests,
        setFetchConnectionRequests,
        userLoggedInChat,
        setUserLoggedInChat,
        stripeCustomer,
        setStripeCustomer,
        activeSubscription,
        setActiveSubscription,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export { NotificationsProvider, NotificationsContext };
