import React, { useEffect, useState } from "react";
import { Route, Redirect } from 'react-router-dom';
import {withRouter} from "react-router-dom";
import Header from "components/Header";
import Footer from "components/Footer";
import AuthHelper from "shared/helpers/AuthHelper";
import { getProfileInfo } from "../../shared/apis/UserService";
import OnBoarding from "../../components/Onboarding";

const PrivateLayout = ({ component: Component, ...rest }) => {
    const authHelper = new AuthHelper();
    let isLoggedIn = authHelper.isLoggedIn();
    const [showOnboarding , setIsShowOnboarding] = useState(null)
    const [values , setValues] = useState([])

    useEffect(() => {
      if (isLoggedIn) {
        checkIfAllDataExist();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkIfAllDataExist = async () => {
      try {
        const data = await getProfileInfo();
        setValues(data);
        const profile = data.profile;
        //check is necessary to show onboarding

        if (profile.role === 'seller' || profile.role === 'Sr') {
          setIsShowOnboarding(false)
        } else if (!data.first_name || !data.last_name || !profile.industries_details.length || !profile.user_industries.length || !profile.expertise || !profile.buyer_type
          || !profile.total_assets_available || !profile.target_company_earnings ||
          !profile.country || !profile.bio || !profile.looking_for_locations
        ) {
          setIsShowOnboarding(true)
        }else{
          setIsShowOnboarding(false)
        }
      } catch (e) {
      }
    }

    return (
    <Route {...rest} render={matchProps => {
      if (!isLoggedIn) {
        // not logged in so redirect to login page with the return url
        return <Redirect to={{ pathname: '/login', state: { from: matchProps.location } }} />
      }
      // authorised so return component
      return (
          <div className="c-private-layout">
              <Header isLoggedIn={isLoggedIn} showIcons={true} showOnboarding={showOnboarding} />
              <main className="c-layout__main c-private-layout__main container-fluid">
                {showOnboarding && <OnBoarding {...rest} {...matchProps} data ={values} setIsShowOnboarding={setIsShowOnboarding} /> }
                {!showOnboarding && showOnboarding !== null &&
                    <Component {...rest} {...matchProps}/>
                }
              </main>
              <Footer />
          </div>
      )
    }} />
  );
};

export default withRouter(PrivateLayout);
