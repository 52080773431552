import API from './ApiBase';

/**
 * Challenge password when it's time to set new one
 * @param id
 * @param email
 * @param oldPassword
 * @param newPassword
 * @returns {Promise<void>}
 */
export const challengePassword = async (id, email, oldPassword, newPassword) => {
  const {data: {data}} = await API.put(`/api/v1/users/${id}/password/`, {
    email,
    oldPassword,
    newPassword
  });
  return data;
};

/**
 * Challenge password when it's time to set new one
 * @param id
 * @param email
 * @param oldPassword
 * @param newPassword
 * @returns {Promise<void>}
 */
export const getProfileInfo = async () => {
  const {data} = await API.get('/api/v1/user/profile/');



  return data;
};

export const stripePost = async (data) => {
  // console.log("this is data", data)
  return await API.post(`/api/v1/stripe/`, data);
};

export const getStripe = async (userId) => {
  return await API.get(`/api/v1/stripe/customer/${userId}/`);
};
export const getMessageNotifications = async (userId) => {
  console.log("requesting message notifications", userId)
  return await API.get(`/api/v1/trigger-message-notifications-check/${userId}/`);
};
// This is no longer use
export const updateProfileInfo = async (data) => {
  // console.log("this is data", data)
  return await API.post(`/api/v1/user/profile_update/`, data);
};
//The one ruby endpoint
export const onboardProfileInfo = async (data) => {
  // console.log("this is data", data)
  return await API.post(`/api/v1/user/profile_onboard/`, data);
};

export const resetVerification = async () => {
  return await API.get(`api/v1/buyers/flinks-reset/`);
};

export const updateProfilePassport = async (data) => {
  // console.log("this is data", data)
  return await API.put(`/api/v1/user/passport/`, data);
};

export const updateProfile = async (data) => {
  // console.log("this is data", data)
  return await API.post(`/api/v1/user/profile_update/`, data);
};

export const contactUs = async (first_name, last_name, email, body) => {
    return await API.post(`/api/v1/contact/`, {
        first_name, last_name, email, body
    });
};

export const getVerifyInfo = async () => {
  return await API.post(`/api/v1/user/verify/`);
};

export const setRoleService = async (role, broker) => {
    return await API.put('/api/v1/user/role/', {
        role,broker
    });
};

export const getPublicUserProfile = async (id) => {
    return await API.get(`/api/v1/user/${id}/`);
};

export const connectToUser = async (id) => {
    return await API.get(`/api/v1/user/${id}/`);
};


export const changeFavoredUser = async (id, isFavor,role) => {
    return await API.post(`/api/v1/user/${id}/favor/`, {
        value: isFavor,
        role: role
    });
};

export const shareMyProfile = async (email, link) => {
    return await API.post(`/api/v1/user/profile_share/`, {email, link});
};

export const updatePassportVerify = async (data) => {
  return await API.put(`/api/v1/user/passport/verify/`, data);
};

export const sendAssessmentReport = async(email) => {
  return await API.post('/api/v1/buyers/assessment-report/', {
    'to_email' : email
  })
}

export const onboardProfileCreate = async (data) => {
  // console.log("this is data", data)
  return await API.post(`/api/v1/user/`, data);
}

export const submitCoupon = async (couponCode) => {
  return await API.get(`/api/v1/coupon/${couponCode}/`, {
  });
}

export const googleLoginPost = async (data) => {
  return await API.post(`/api/v1/google-login/`, data);
}