import React, { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMount } from "ahooks";
import {
  Button,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import { RemoveRedEyeOutlined } from "@material-ui/icons";
import OnboardingBreadcrumbs from "../shared/OnboardingBreadcrumbs";
import "./index.scss";
import {
  BackButton,
  BreadcrumbsClass,
  ButtonContainer,
  CenterNextButton,
  FormContainerClass,
  LabelClass,
  NextButton,
  OnboardingStepContainerClass,
  TitleClass,
  EmptyDiv,
} from "../shared/BootStrapStyling";
import { ErrorMessage } from "@hookform/error-message";
import { aboutYou2 } from "../../../shared/apis/OnboardingService"; 

const StepThree = ({ defaultValue, onBack, onSubmit }) => {
  const { handleSubmit, errors, control } = useForm({
    defaultValues: {
      businesses_owned_code: defaultValue?.businesses_owned_code ?? null,
      linked_in_url: defaultValue?.linked_in_url ?? "",
    },
  });

  const IsSubmitted = useRef(false);

  const [linkedIn, setLinkedIn] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useMount(() => {
    setLinkedIn(defaultValue?.linked_in_url ?? "");
  });

  const onSubmitStepThree = async (data) => {
    const updatedValues = {
      ...defaultValue,
      businesses_owned_code: data.businesses_owned_code,
      linked_in_url: linkedIn ? String(linkedIn).trim() : "",
    };
    if (IsSubmitted.current) {
      setIsSubmitting(true);
      try {
        await aboutYou2(updatedValues);     
        setIsSubmitting(false);
        onSubmit(updatedValues);
      } catch (error) {
        setIsSubmitting(false);
      }
    } else {
      onBack(updatedValues);
    }
  };
  const onClickBack = () => {
    IsSubmitted.current = false;
  };

  const onClickSubmit = () => {
    IsSubmitted.current = true;
  };

  return (
    <div className={`${OnboardingStepContainerClass}`}>
      <div className={`${BreadcrumbsClass}`}>
        <OnboardingBreadcrumbs currentStep={1} />
      </div>
      <form
        id="stepThree"
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit(onSubmitStepThree)}
        className={`${FormContainerClass}`}
      >
        <div className="form-body">
          <h1 className={`${TitleClass} pb-5`}>Work experience & skills</h1>
          <label className={`${LabelClass} pt-3`}>
            How many businesses have you owned?
          </label>
          <Controller
            name="businesses_owned_code"
            control={control}
            rules={{ required: "Count of owned businesses is required" }}
            render={({ onChange, value, ref }) => (
              <RadioGroup
                name="businesses_owned_code"
                row
                aria-label="businesses_owned_code"
                onChange={(e) => onChange(Number(e.target.value))}
                value={value}
                inputRef={ref}
              >
                <FormControlLabel
                  value={0}
                  control={<Radio color="primary" />}
                  label="Zero"
                />
                <FormControlLabel
                  value={1}
                  control={<Radio color="primary" />}
                  label="1-2"
                />
                <FormControlLabel
                  value={2}
                  control={<Radio color="primary" />}
                  label="2+"
                />
              </RadioGroup>
            )}
          />
          <ErrorMessage
            className="error-message"
            errors={errors}
            name="businesses_owned_code"
            as="p"
          />
          <label className={`${LabelClass} pt-3`}>LinkedIn URL</label>
          <TextField
            required
            id="linkdin"
            name="linkdin"
            variant="outlined"
            className="w-100"
            placeholder="ex. https://www.linkedin.com/in/mikevillagewellth/"
            value={linkedIn ? String(linkedIn).trimStart() : ""}
            onChange={(e) => setLinkedIn(e.target.value)}
          />
          <div className="d-flex-column lg:d-flex-row align-items-center w-100 pb-4 pt-1 px-1">
            <RemoveRedEyeOutlined fontSize="small" />
            <small
              style={{ color: "#7D7F80;", fontFamily: "Inter-Bold" }}
              className="text-muted px-2"
            >
              {" "}
              Not displayed in searchable profile
            </small>
          </div>
        </div>
        <div className={`${ButtonContainer}`}>
          <div>
            <Button
              startIcon={<ArrowBackIcon />}
              type="submit"
              onClick={onClickBack}
              className={`${BackButton}`}
            >
              {"Previous"}
            </Button>
          </div>
          <div className={`${CenterNextButton}`}>
            <Button
              type="submit"
              onClick={onClickSubmit}
              className={`${NextButton}`}
              disabled={isSubmitting}
            >
              { isSubmitting ? <CircularProgress color="secondaryTextColor" size="1.9rem" /> : "Next" }
            </Button>
          </div>
          <div className={`${EmptyDiv}`}>
            {/*this element completes the three column layout and allows the 'next' button to be centered*/}
          </div>
        </div>
      </form>
    </div>
  );
};

export default StepThree;
