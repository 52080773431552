import React, { useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { Button, CircularProgress, FormControlLabel, InputAdornment, Radio, RadioGroup, TextField } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import './index.scss'
import {
  BackButton,
  BreadcrumbsClass, ButtonContainer, CenterNextButton, EmptyDiv,
  FormContainerClass, LabelClass, NextButton,
  OnboardingStepContainerClass,
  TitleClass
} from "../shared/BootStrapStyling";
import OnboardingBreadcrumbs from "../shared/OnboardingBreadcrumbs";
import { ErrorMessage } from "@hookform/error-message"
import { allowIntegersOnly } from '../../../shared/helpers/utils';
import { finance } from '../../../shared/apis/OnboardingService';

const StepSix = ({ onSubmit, defaultValue, onBack }) => {
  const { control, errors, handleSubmit, setValue } = useForm({ mode: 'onBlur',
    defaultValues: {
      totalAssetsAvailable: defaultValue?.total_assets_available ?? null,
      purchaseWithOthers: defaultValue?.purchase_with_others  ?? null,
      engagedWithLendingInstitution: defaultValue?.engaged_with_lending_institution ?? null
    }
  })

  const IsSubmitted = useRef(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmitStepSix = async (data) => {
    const values = {
      ...defaultValue,
      total_assets_available: data.totalAssetsAvailable,
      purchase_with_others: data.purchaseWithOthers,
      engaged_with_lending_institution: data.engagedWithLendingInstitution,
    }
    if (IsSubmitted.current && (data.totalAssetsAvailable || data.totalAssetsAvailable === 0)) {
      setIsSubmitting(true);
      try {
        await finance(values);
        setIsSubmitting(false);
        onSubmit(values);
      } catch (e) {
        setIsSubmitting(false);
      }
        
    } else if (data.totalAssetsAvailable  || data.totalAssetsAvailable === 0) {
      onBack(values);
    } else {
      setValue('totalAssetsAvailable', null)
    }
  };

  const onClickBack = () => {
    IsSubmitted.current = false;
  };

  const onClickSubmit = () => {
    IsSubmitted.current = true;
  };

  return (
    <div className={`${OnboardingStepContainerClass}`}>
      <div className={`${BreadcrumbsClass}`}><OnboardingBreadcrumbs currentStep={3} /></div>

      <form
      id="stepSix"
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit(onSubmitStepSix)}
        className={`${FormContainerClass}`}
      >
        <h1 className={`${TitleClass}`}>Let's talk numbers.</h1>
        <label  className={`${LabelClass}`}>What is your budget for a down payment/ cash portion toward this acquisition?</label>
        <small  className={`pb-3 font-light`}>This is the amount we will authenticate when getting verified</small>
        <Controller
          name="totalAssetsAvailable"
          control={control}
          rules={{
            required: "Down payment budget is required",
            min:{ value: 1, message: "Down payment budget must be greater than 0" },
          }}
          render={({ onChange, value, ref }) => (
            <TextField
              name="totalAssetsAvailable"
              variant='outlined' 
              fullWidth
              placeholder='e.g. 500000'
              onKeyDown={allowIntegersOnly}
              value={value}
              onChange={(e) => onChange(Number(e.target.value))}
              InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
              inputRef={ref}
            />
          )}
        />
        <ErrorMessage 
          className="error-message mt-1" 
          errors={errors} 
          name="totalAssetsAvailable" 
          as="p" 
        />
        <label className={`${LabelClass} pt-3`}>Do you have access to additional investor capital?</label>
        <div>
          <Controller
            name="purchaseWithOthers"
            control={control}
            rules={{ required: "Please indicate whether you have access to additional capital" }}
            render={({ onChange, value, ref }) => (
              <RadioGroup
                name="purchaseWithOthers"
                row
                onChange={(e) => onChange(e.target.value)}
                value={value}
                inputRef={ref}
              >
                <FormControlLabel
                  value={"Yes"}
                  control={<Radio color="primary" />}
                  label="Yes"
                />
                <FormControlLabel
                  value={"No"}
                  control={<Radio color="primary" />}
                  label="No"
                />
              </RadioGroup>
            )}
          />
        </div>
        <ErrorMessage
          className="error-message"
          errors={errors}
          name="purchaseWithOthers"
          as="p"
        />
        <label className={`${LabelClass}`}>Will you require financing?</label>
        <div>
          <Controller
            name="engagedWithLendingInstitution"
            control={control}
            defaultValue={null}
            rules={{ required: "Please indicate whether you require financing or not"}}
            render={({ onChange, value, ref }) => (
              <RadioGroup
                name="engagedWithLendingInstitution"
                row
                onChange={(e) => onChange(e.target.value)}
                value={value}
                inputRef={ref}
              >
                <FormControlLabel
                  value={"Yes"}
                  control={<Radio color="primary" />}
                  label="Yes"
                />
                <FormControlLabel
                  value={"No"}
                  control={<Radio color="primary" />}
                  label="No"
                />
              </RadioGroup>
            )}
          />
        </div>
        <ErrorMessage 
          className="error-message" 
          errors={errors} 
          name="engagedWithLendingInstitution"
          as="p" 
        />
        <div className={`${ButtonContainer}`}>
          <div>
            <Button startIcon={<ArrowBackIcon />} type='submit' onClick={onClickBack}
                    className={`${BackButton}`}>
              {'Previous'}
            </Button>
          </div>
          <div className={`${CenterNextButton}`}>
            <Button 
              type='submit' 
              onClick={onClickSubmit}  
              className={`${NextButton}`}
              disabled={isSubmitting}
            >
              { isSubmitting ? <CircularProgress color="secondaryTextColor" size="1.9rem" /> : "Next" }
            </Button>
          </div>
          <div className={`${EmptyDiv}`}>{/*this element completes the three column layout and allows the 'next' button to be centered*/}</div>
        </div>
      </form>
    </div>
  );
};

StepSix.defaultProps = {};

StepSix.propTypes = {};

export default StepSix;
