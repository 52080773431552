

export const ROLES = {
    "Br": "buyer",
    "buyer": "buyer",
    "Sr": "seller",
    "seller": "seller",
    "admin": "admin",
    "Ad": "admin"
}
// export const BADGE_ICONS = {
//     "Operator": OperatorIcon,
//     "Individual": IndividualIcon,
//     "Accredited-investor": AccreditedInvestorIcon,
//     "Available-cash": AvailableCashIcon,
//     "Experienced-owner": ExperiencedOwnerIcon,
// };
export const EXPERTISES = {
    "Op": "Operator",
    "Pi": "Passive Investor",
    "Ad": "Advisor",
    "Mi": "Mixed(all)",
};
export const HOW_YOU_HEAR = {
    "Op": "Operator",
    "Pi": "Passive Investor",
    "Ad": "Advisor",
    "Mi": "Mixed(all)",
};
export const TARGET_PREFERENCE = {
    "BM": "Brick & Mortar",
    "ON": "Online",
    "BO": "Brick & Mortar & Online",
};

export const BUYER_PROFILE_TYPES = [
    {
        label: 'I am an individual investor',
        display: 'Individual buyer',
        value: 'In'
    },
    {
        label: 'We are a group of individuals',
        display: 'Group of buyers',
        value: 'GI'
    },
    {
        label: 'I represent an existing business',
        display: 'I represent an existing business',
        value: 'EO'
    },
    {
        label: 'I represent a non-profit organization',
        display: 'Non-profit organization',
        value: 'NP'
    },
    {
        label: 'Private equity',
        display: 'Private equity',
        value: 'PE',
    },
    {
        label: 'Co-op',
        display: 'Co-op',
        value: 'CP'
    }
]

export const BUSINESS_ROLES = [
    {
        label: 'Passive Investor',
        value: 'Pi'
    },
    {
        label: 'Owner/Operator',
        value: "Op"
    },
    {
        label: 'Advisor',
        value: 'Ad'
    },
    {
        label: 'Mixed(all)',
        value: 'Mi'
    }
]

export const ADDITIONAL_INVESTORS = [
    {
        label : 'Yes',
        value : 'Yes'
    },
    {
        label : 'No',
        value : 'No'
    },
    {
        label : 'Unsure',
        value : 'Unsure'
    }
]

export const FINANCING_OPTIONS = [
    {
        label : 'Seller Financing Available',
        value : 'Yes'
    },
    {
        label : 'Seller Financing Not Available',
        value : 'No'
    },
    {
        label : 'Subject to Negotiation',
        value : 'Maybe'
    }
]
