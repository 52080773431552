import React, { Suspense } from 'react';
import {BrowserRouter, Switch} from "react-router-dom";
import Loading from "components/Loading/index";
import DefaultLayout from "layouts/DefaultLayout/index";
import BuyerLayout from "layouts/BuyerLayout/index";
import BuyerLayoutNoIcons from "layouts/BuyerLayoutNoIcons/index";
import SellerLayout from "layouts/SellerLayout/index";
import SellerLayoutNoIcons from "layouts/SellerLayoutNoIcons/index";
import PrivateLayout from "layouts/PrivateLayout/index";
import AccountLayout from "./layouts/AccountLayout/index";
import AccountV2Layout from "./layouts/AccountV2Layout/index";
import ProfileLayout from './layouts/ProfileLayout';
import DealsLayout from './layouts/DealsLayout';
import { NotificationsProvider } from 'components/NotificationsProvider/NotificationsProvider';
import HotjarUserTracker from './shared/helpers/HotJarDetails';
import { GoogleOAuthProvider } from '@react-oauth/google';



const loading = Loading();

const Login = React.lazy(() => import('containers/LoginV2/index'));

const Register = React.lazy(() => import('containers/RegisterV2/index'));

const BrowseAll = React.lazy(() => import('containers/BrowseAll/index'));

const FindBuyers = React.lazy(() => import('containers/FindBuyers/index'));

const CalculatorAfford = React.lazy(() => import('containers/CalculatorAfford/index'));

const ComingSoon = React.lazy(() => import('containers/ComingSoon/index'));

const Home = React.lazy(() => import('containers/Home/Home'));

const BuyerMarketing = React.lazy(() => import('containers/BuyerMarketing/index'));

const SellerMarketing = React.lazy(() => import('containers/SellerMarketing/index'));

const Dashboard = React.lazy(() => import('containers/Dashboard/index'));

const UserProfile = React.lazy(() => import('containers/UserProfile/UserProfile'));

const AccountSettings = React.lazy(() => import('containers/AccountSettings/AccountSettings'));

const PublicBuyerProfile = React.lazy(() => import('containers/PublicBuyerProfile/PublicBuyerProfile'));

const FindCompanies = React.lazy(() => import('containers/FindCompanies/index'));

const PublicSellerProfile = React.lazy(() => import('containers/PublicSellerProfile/index'));

const Contact = React.lazy(() => import('containers/Contact/Contact'));

const ResetPassword = React.lazy(() => import('containers/ResetPassword/index'));

const ForgetPassword = React.lazy(() => import('containers/ForgetPassword/index'));

const SellerOnboarding = React.lazy(() => import('containers/SellerOnboarding/index'));

const SellerEditProfile = React.lazy(() => import('containers/SellerEditProfile/index'));

const CreateOpportunity = React.lazy(() => import('containers/CreateOpportunity/CreateOpportunity'));

// const ManageOpportunities = React.lazy(() => import('containers/ManageOpportunities/ManageOpportunities'));

const FlinksExternal = React.lazy(() => import('containers/FlinksExternal/index'));

const TermsOfUse = React.lazy(()=> import('containers/TermsOfUse/index') )

const BuyerSubscribe = React.lazy(()=> import('containers/BuyerSubscribe/BuyerSubscribe.jsx') )

const BuyerOnboardSubscribe = React.lazy(()=> import('containers/BuyerOnboardSubscribe/BuyerOnboardSubscribe.jsx') )

const SellerSettings = React.lazy(()=> import('containers/SellerSettings/SellerSettings') )

const SubscriptionSuccess = React.lazy(()=> import('containers/SubscriptionSuccess/SubscriptionSuccess') )

const SellerSubscriptionSuccess = React.lazy(()=> import('containers/SellerSubscriptionSuccess/SellerSubscriptionSuccess') )

const SellerSubscribe = React.lazy(()=> import('containers/SellerSubscribe/SellerSubscribe') )

const SellerProfileComplete = React.lazy(()=> import('containers/SellerProfileComplete/SellerProfileComplete') )

const BuyerSearch = React.lazy(()=> import('containers/BuyerSearch/index') )

const MyDeals = React.lazy(()=> import('containers/MyDeals/MyDeals') )

const SellerDeals = React.lazy(()=> import('containers/SellerDeals/SellerDeals') )

const PublicListing = React.lazy(()=> import('containers/PublicListing/PublicListing') )

const SellerSearch = React.lazy(()=> import('containers/SellerSearch/SellerSearch') )




function App() {
    return (
        <GoogleOAuthProvider clientId="285614275563-rnuvklil2p6jjpghv25b82oqcehuo84l.apps.googleusercontent.com">
        <Suspense fallback={loading}>
               <NotificationsProvider>
               <HotjarUserTracker />
            <BrowserRouter>
                <Switch>
                   

                
                    {<DefaultLayout exact enableRedirect={true} path="/" component={Home}/>}
                 
                    <DefaultLayout exact path="/contact-us" component={Contact}/>
                    <DefaultLayout exact path="/buyer-marketing" component={BuyerMarketing}/>
                    <DefaultLayout exact path="/seller-marketing" component={SellerMarketing}/>
                    <ProfileLayout exact path="/buyers" component={BuyerSearch}/>
                    <ProfileLayout exact path="/opportunities" component={SellerSearch}/>
                    <DefaultLayout exact path="/find-buyers" component={FindBuyers}/>
                    <DefaultLayout exact path="/find-companies" component={FindCompanies}/>
                    <DefaultLayout exact path="/calculator-afford" component={CalculatorAfford}/>
                    <DefaultLayout exact path="/terms-of-use" component={TermsOfUse} />
                    <AccountV2Layout exact enableRedirect={true} path="/register" component={Register}></AccountV2Layout>
                    <AccountV2Layout exact enableRedirect={true} path="/login" component={Login}></AccountV2Layout>
                    <AccountV2Layout exact path="/forget-password" component={ForgetPassword}></AccountV2Layout>
                    <AccountV2Layout exact path="/reset-password/" component={ResetPassword}></AccountV2Layout>
                    <SellerLayoutNoIcons exact path="/sellerOnboarding" component={SellerOnboarding}/>
                    <SellerLayout exact path="/editSellerProfile" component={SellerEditProfile}/>
                    <SellerLayout exact path="/createOpportunity" component={CreateOpportunity}/>
                    <DealsLayout exact path="/seller-deals" component={SellerDeals}/>
                    <SellerLayout exact path="/seller-settings" component={SellerSettings}/>
                    <SellerLayout exact path="/seller-subscription" component={SellerSubscribe}/>
                    <SellerLayout exact path="/seller-subscription-success" component={SellerSubscriptionSuccess}/>
                    <SellerLayout exact path="/seller-profile-complete" component={SellerProfileComplete}/>
                    <PrivateLayout exact path="/seller/:id" component={PublicSellerProfile}></PrivateLayout>
                    <BuyerLayout exact path="/dashboard" component={Dashboard}/>
                    <BuyerLayout exact path="/profile" component={UserProfile}/>
                    <BuyerLayout exact path="/account-settings" component={AccountSettings}/>
                    <BuyerLayout exact path="/buyer-subscribe" component={BuyerSubscribe}/>
                    <DealsLayout exact path="/my-deals" component={MyDeals}/>
                    <PrivateLayout exact path="/browse-all" component={BrowseAll}/>
                    <BuyerLayout exact path="/subscription-success" component={SubscriptionSuccess}/>
                    <BuyerLayoutNoIcons exact path="/buyer-onboarding-subscription" component={BuyerOnboardSubscribe}/>
                    <ProfileLayout exact path="/buyer/:id" component={PublicBuyerProfile}/>
                    <ProfileLayout exact path="/opportunity/:id" component={PublicListing}/>
                    <DefaultLayout exact path="/verify-buyer" component={FlinksExternal} isExternal={true}/>
                    <AccountLayout component={ComingSoon}></AccountLayout>
                    
                </Switch>
            </BrowserRouter>
            </NotificationsProvider>
        </Suspense>
        </GoogleOAuthProvider>
    )
}

export default App;
