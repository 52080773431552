import React, { useState } from "react";
import { Route, Redirect } from "react-router-dom";

import AuthHelper from "shared/helpers/AuthHelper";
import Header from "components/Header";
import Footer from "components/Footer";






const AccountV2Layout = ({ component: Component, enableRedirect, ...rest }) => {

  const authHelper = new AuthHelper();
  let isLoggedIn = authHelper.isLoggedIn();
  const [role, setRole] = useState("");
  const isCredentialScreen = rest.path !=='/login' && rest.path !== '/register'
  return (
    <Route
      {...rest}
      render={matchProps => {
        if (enableRedirect && isLoggedIn) {
          return (
            <Redirect
              to={{
                pathname: "/dashboard",
                state: { from: matchProps.location },
              }}
            />
          );
        }
        return (
          <div className="c-account-layout">
            <Header showIcons={false} />
            <main className="c-layout__main c-account-layout__main container-fluid">
     
              
                <div className="register col-12 col-md-5 pr-0 justify-content-center align-items-center auth-form">
                  <Component
                    {...rest}
                    {...matchProps}
                    setRole={setRole}
                    role={role}
                  />
          
                
                {/* <div className="c-account-component col-12 col-md-5 offset-md-2 my-2 my-md-5 login-testimonials d-flex justify-content-center flex-column"> */}
                {/* {FEATURES[role] && FEATURES[role].map(({ title, img, comingSoon }, index) => (
                  <div key={index} className={`d-flex ${index === (FEATURES[role].length - 1) ? 'mb-0' : 'mb-2 mb-md-5'}`}>
                    <img className="mr-3" src={img} alt="user" width={28} height={28} />
                    <div className="d-inline-table">
                      <span>{title}</span>
                      {comingSoon &&
                        <div className="coming-soon">Coming Soon</div>
                      }
                    </div>
                  </div>
                ))} */}
                {/* </div> */}
              </div>
            </main>
            {isCredentialScreen && <Footer />}
          </div>
        );
      }}
    />
  );
};

export default AccountV2Layout;
