import React from "react";
import Button from "@material-ui/core/Button/Button";
import classnames from 'classnames'
import './index.scss'


const BeginCreationProfile = ({
  title, subTitle, subTitle2, subTitle3, boldText,
  btnText, isBeginCreate, onBeginCreateProfile, step2 = false
}) => {

  return (
    <div className={classnames('BeginCreationProfile', { isDisplayNone: isBeginCreate })}>
      <div className='section-title'>
        <h1>{title}</h1>
        <div className="sub-title"><span>{subTitle}</span></div>
        <div className="sub-title">{subTitle2} <span
          style={{ fontFamily: "Inter-SemiBold" }}>{boldText}</span> {subTitle3}</div>
      </div>
      {/* <div className="steps">
        <div className='pb-5'>
          <StepBox stepNumber={'Step 1: '} stepTitle={'Build Acquisition Profile'} disabled={step2}
            stepImage={ProfileIcon} />
        </div>
        <StepBox stepNumber={'Step 2: '} stepTitle={'ID & Financial Verification'} disabled={!step2}
          stepImage={CashFinanceIcon} />
        <div>
        </div>
      </div> */}
      <div className={'btn pt-5'}>
        <Button onClick={onBeginCreateProfile} className="h-auto w-100 p-2 px-lg-5 border">
          {btnText}
        </Button>
      </div>
    </div>
  )
}
export default BeginCreationProfile;
