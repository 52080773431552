import React from "react";

import { Button } from "@material-ui/core";
import "../shared/stepForm.scss";
import "./index.scss";
import {
  TitleClass,
  FormContainerClass,
  OnboardingStepContainerClass,
  CenterNextButton,
  LabelClass,
} from "../shared/BootStrapStyling";
import one from "../../../assets/images/Onboard1.svg";
import two from "../../../assets/images/Onboard2.svg";
import three from "../../../assets/images/Onboard3.svg";


function ProfileComplete() {
 

  function onClickSubmit() {
    window.location.href = "/buyer-subscribe";
  }

  return (
    <div className={`${OnboardingStepContainerClass}`}>
      <div className={`${FormContainerClass}`}>
        <div className="form-body center">
          <h1 className={`${TitleClass}`}>Welcome to Village Wellth!</h1>

          <div className="row px-0 mx-0">
            <div className="live-profile mr-1 mb-5">
              Your profile is now live!{" "}
            </div>{" "}
            <span> We are excited that you are here.</span>
          </div>

          <div className={`${LabelClass} mb-3`}>How it works?</div>
          <div className="d-flex align-items-center ">
            <img className=" mb-4" src={one} alt="1"></img>
            <p className="font-light p-2">
              Sellers search for buyers based on your criteria.
            </p>
          </div>
          <div className="d-flex align-items-center ">
            <img className=" mb-4" src={two} alt="2"></img>
            <p className="font-light p-2">
              You receive connection requests including opportunity details.
            </p>
          </div>
          <div className="d-flex align-items-center ">
            <img className=" mb-4" src={three} alt="3"></img>
            <p className="font-light p-2">
              Upgrade and verify profile to 10x connection requests.
            </p>
          </div>
        </div>
        <div id="buttons" className={`${CenterNextButton} pt-5`}>
          <Button
            type="submit"
            variant="contained"
            onClick={onClickSubmit}

            color="primary"
            className="mt-5 mb-4 w-50"
          >
            NEXT
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ProfileComplete;
