import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import Header from "components/Header";
import Footer from "components/Footer";
import AuthHelper from "shared/helpers/AuthHelper";
import OnBoarding from "../../components/Onboarding";
import { getProfileInfo } from "../../shared/apis/UserService";
import { Backdrop, CircularProgress } from "@material-ui/core";

const BuyerLayout = ({ component: Component, ...rest }) => {
  const authHelper = new AuthHelper();
  let isLoggedIn = authHelper.isLoggedIn();

  const [showOnboarding, setIsShowOnboarding] = useState(null);
  const [onboardingComplete, setOnboardingComplete] = useState(false);
  const [values, setValues] = useState([]);

  useEffect(() => {
    (async () => {
      const data = await getProfileInfo();
      const profile = data.profile;
      // console.log("profile, data", profile);
      //check is necessary to show onboarding
      setValues(data);

      //check is necessary to show onboarding
      if (profile.role === "seller" || profile.role === "Sr") {
        setIsShowOnboarding(false);
      } else if (
        (data.first_name === "" ||
          data.last_name === "" ||
          !profile.industries_details.length ||
          !profile.user_industries.length ||
          profile.expertise === "" ||
          !profile.buyer_type ||
          !profile.total_assets_available ||
          !profile.target_company_earnings ||
          !profile.country ||
          profile.bio === "" ||
          !profile.looking_for_locations ||
          !profile.looking_for_locations.length)  &&
        !onboardingComplete
      ) {
        setIsShowOnboarding(true);
      } else {
        setIsShowOnboarding(false);
      }
    })();
    //  eslint-disable-next-line
  }, []);

  return (
    <Route
      {...rest}
      render={(matchProps) => {
        if (!isLoggedIn) {
          // not logged in so redirect to login page with the return url
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: matchProps.location } }}
            />
          );
        } else if (authHelper.getRole() === "seller") {
          return (
            <Redirect
              to={{ pathname: "/buyers", state: { from: matchProps.location } }}
            />
          );
        }
        // authorised so return component
        return (
          <div className="c-private-layout">
            <Header
              isLoggedIn={isLoggedIn}
              showIcons={true}
              showOnboarding={showOnboarding}
            />
            <main className="c-layout__main c-private-layout__main container-fluid">
              {showOnboarding && (
                <OnBoarding
                  {...rest}
                  {...matchProps}
                  data={values}
                  setIsShowOnboarding={setIsShowOnboarding}
                  setOnboardingComplete={setOnboardingComplete}
                />
              )}
              {!showOnboarding && showOnboarding !== null && (
                <Component {...rest} {...matchProps} />
              )}
              <Backdrop id="loading-dialog" open={showOnboarding === null}>
                <CircularProgress />
              </Backdrop>
            </main>
            <Footer />
          </div>
        );
      }}
    />
  );
};

export default BuyerLayout;
