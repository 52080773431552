import API from './ApiBase';

export const aboutYou1 = async (data) => {
  return await API.put(`/api/v1/user/onboarding/you-1/`, data);
}

export const aboutYou2 = async (data) => {
  return await API.put(`/api/v1/user/onboarding/you-2/`, data);
}

export const industry1 = async (data) => {
  return await API.put(`/api/v1/user/onboarding/industry-1/`, data);
}

export const industry2 = async (data) => {
  return await API.put(`/api/v1/user/onboarding/industry-2/`, data);
}

export const finance = async (data) => {
  return await API.put(`/api/v1/user/onboarding/finance-1/`, data);
}