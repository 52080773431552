import {
  Drawer,
  Box,
  Link,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { menuIcon } from "../../assets/images";
import "./MenuDrawer.scss";
import React, { useEffect, useState, useContext } from "react";
import AuthHelper from "shared/helpers/AuthHelper";
import search from "../../assets/images/searchnormal1.svg";
import clipboardtext from "../../assets/images/clipboardtext.svg";
import editProfile from "../../assets/images/editProfile.svg";
import vwLogo from "assets/images/vw-header-logo-new.PNG";
import notification from "../../assets/images/notification.svg";
import noNotification from "../../assets/images/no-notification.svg";
import { useHistory } from "react-router-dom";
import {
  getProfileInfo,
  getStripe,
  stripePost,
} from "../../shared/apis/UserService";
import { NotificationsContext } from "../NotificationsProvider/NotificationsProvider";
const menuLink = [
  {
    id: 1,
    label: "Subscription",
    url: "/buyer-subscribe",
  },
  {
    id: 1,
    label: "Account settings",
    url: "/account-settings",
  },
  {
    id: 2,
    label: "Log out",
    url: "/logout",
  },
];
const menuLinkSeller = [
  {
    id: 0,
    label: "Subscription",
    url: "/seller-subscription",
  },
  {
    id: 1,
    label: "Edit profile",
    url: "/editSellerProfile",
  },
  {
    id: 2,
    label: "Account settings",
    url: "/seller-settings",
  },

  {
    id: 3,
    label: "Log out",
    url: "/logout",
  },
];
const menuRegister = [
  {
    id: 0,
    label: "Log in",
    url: "/login",
  },
  {
    id: 1,
    label: "Create Account",
    url: "/register",
  },
];

const MenuDrawer = (props) => {
  const history = useHistory();
  const authHelper = new AuthHelper();
  const isLoggedIn = authHelper.isLoggedIn();
  const role = authHelper.getRole();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [stripeCustomer, setStripeCustomer] = useState(null);
  const [user, setUser] = useState(null);
   const {
    unreadMessages,
    userConnectionRequests,
  } = useContext(NotificationsContext);
  const onClick = (url) => {
    console.log(url);
    if (url === "/logout") {
      authHelper.logout(history);
    } else if (url === "/buyer-subscribe" && stripeCustomer) {
      stripe();
    } else if (url === "/seller-subscription" && stripeCustomer) {
      stripe();
    } else {
      history.push(url);
    }
  };
  const isActive = useMediaQuery("(max-width: 1150px)");
  const isMarketing = useMediaQuery("(max-width: 1349px)");
  const getData = async () => {
    try {
      const userInfo = await getProfileInfo();

      setUser(userInfo.id);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchStripe = async () => {
    if (user) {
      try {
        const response = await getStripe(user);
        if (response.status === 200) {
          if (response.data.results[0]) {
            setStripeCustomer(response.data.results[0].stripe_customer_id);
          }
        } else {
          setStripeCustomer(null);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const stripe = async () => {
    if (authHelper.isLoggedIn()) {
      try {
        let customer = { customer: stripeCustomer, role: role };

        const getStripe = await stripePost(customer);

        window.location = getStripe.data.url;
      } catch (error) {
        console.log(error);
      }
    }
  };
  
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    fetchStripe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  return (
    <>
      <button
        className={
          isLoggedIn
            ? isActive
              ? "drawer-button"
              : "d-none"
            : isMarketing
            ? "drawer-button"
            : "d-none"
        }
        onClick={() => {
          setDrawerOpen(true);
        }}
      >
        <img src={menuIcon} alt="menu" />
      </button>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={() => {
          setDrawerOpen(false);
        }}
      >
        <Box p={2} width="250px" textAlign="left" role="presentation">
          {/* <Typography variant="h5" component="div">
            Menu
          </Typography> */}
          <Link
            href={isLoggedIn ? "/buyers" : "/"}
            className="font-bold text-decoration-none"
          >
            <img
              alt="village wellth logo"
              src={vwLogo}
              className="drawer-logo mb-5"
            />
          </Link>

          {!isLoggedIn ? (
            <div>
              {/* <Link
                className="header-link-color text-decoration-none header-link"
                href="/buyer-marketing"
              >
                For Buyers
              </Link>
              <br></br>

              <div className="drawer-link">
                <Link
                  className="header-link-color text-decoration-none header-link"
                  href="/seller-marketing"
                >
                  For Sellers
                </Link>
                <br></br>
              </div> */}
              <div className="drawer-link">
                <Link
                  className="header-link-color text-decoration-none header-link "
                  href="/buyers"
                >
                  Find Buyers
                </Link>
              </div>
              <div className="drawer-link">
                <Link
                  className="header-link-color text-decoration-none header-link "
                  href="/opportunities"
                >
                  Find Deals
                </Link>
              </div>
              <div className="">
                  <Accordion className="accordian " elevation={0}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className="accordian "
                    >
                      <Typography className="header-link-color text-decoration-none header-link">
                        {" "}
                        
                        Resources
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <List className="accordian">
                        <ListItem className="accordian-list-item">
                          <Link
                            className="accordian-list-item"
                            href="https://villagewellth.com/financing/"
                          >
                            Financing
                          </Link>
                        </ListItem>
                        <ListItem className="accordian-list-item">
                          <Link
                            className="accordian-list-item"
                            href="https://villagewellth.com/blog/"
                          >
                            Blog
                          </Link>
                        </ListItem>
                        {/* <ListItem className="accordian-list-item">
                          <Link
                            className="accordian-list-item"
                            href="https://blog.villagewellth.com/microservices/"
                          >
                            Micro Services
                          </Link>
                        </ListItem> */}
                        <ListItem className="accordian-list-item">
                          <Link
                            className="accordian-list-item"
                            href="https://villagewellth.com/blog/buying-a-business-masterclass/"
                          >
                            Masterclass
                          </Link>
                        </ListItem>
                      </List>
                    </AccordionDetails>
                  </Accordion>
                </div>

            </div>
          ) : (
            <div>
              <div className="drawer-link">
                <Link
                  className="header-link-color text-decoration-none header-link "
                  href="/buyers"
                >
                  <img alt="" src={search} className="mt-0 pb-1" /> Find
                  Buyers
                </Link>
              </div>
              <div className="drawer-link">
                <Link
                  className="header-link-color text-decoration-none header-link "
                  href="/opportunities"
                >
                  <img alt="" src={search} className="mt-0 pb-1" /> Find
                  Deals
                </Link>
              </div>
              {role === "buyer" ? (
                <>
                  <div className="drawer-link">
                    <Link
                      className="header-link-color text-decoration-none header-link "
                      href="/profile"
                    >
                      <img alt="" src={editProfile} className="mt-0 pb-1" />{" "}
                      Profile
                    </Link>
                  </div>
                  <div className="drawer-link">
                    <Link
                      className="header-link-color text-decoration-none header-link "
                      href="/my-deals"
                    >
                      {userConnectionRequests ? (
                        <img alt="" src={notification} className="mt-0 pb-1" />
                      ) : (
                        <img
                          alt=""
                          src={noNotification}
                          className="mt-0 pb-1"
                        />
                      )}
                      My Deals
                    </Link>
                  </div>
                </>
              ) : (
                <div></div>
              )}

              {role === "seller" && (
                <div className="drawer-link">
                  <Link
                    className="header-link-color text-decoration-none header-link "
                    href="/seller-deals"
                  >
                      {userConnectionRequests || unreadMessages.length ? (
                        <img alt="" src={notification} className="mt-0 pb-1" />
                      ) : (
                        <img
                          alt=""
                          src={noNotification}
                          className="mt-0 pb-1"
                        />
                      )}
                    My Deals
                  </Link>
                </div>
              )}
                <div className="">
                  <Accordion className="accordian " elevation={0}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className="accordian "
                    >
                      <Typography className="header-link-color text-decoration-none header-link">
                        {" "}
                        <img
                          alt=""
                          src={clipboardtext}
                          className="mt-0 pb-1"
                        />{" "}
                        Resources
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <List className="accordian">
                        <ListItem className="accordian-list-item">
                          <Link
                            className="accordian-list-item"
                            href="https://www.villagewellth.com/financing"
                          >
                            Financing
                          </Link>
                        </ListItem>
                        <ListItem className="accordian-list-item">
                          <Link
                            className="accordian-list-item"
                            href="https://blog.villagewellth.com/"
                          >
                            Blog
                          </Link>
                        </ListItem>
                        <ListItem className="accordian-list-item">
                          <Link
                            className="accordian-list-item"
                            href="https://blog.villagewellth.com/microservices/"
                          >
                            Micro Services
                          </Link>
                        </ListItem>
                        <ListItem className="accordian-list-item">
                          <Link
                            className="accordian-list-item"
                            href="https://blog.villagewellth.com/buying-a-business-masterclass/"
                          >
                            Masterclass
                          </Link>
                        </ListItem>
                      </List>
                    </AccordionDetails>
                  </Accordion>
                </div>
             
            </div>
          )}
          <hr />
          {isLoggedIn ? (
            role === "seller" ? (
              <List>
                {menuLinkSeller.map((value, index) => (
                  <ListItem
                    className="header-link-color text-decoration-none header-link"
                    key={index}
                    button
                    onClick={() => onClick(value.url)}
                  >
                    <ListItemText primary={value.label} />
                  </ListItem>
                ))}
              </List>
            ) : (
              <List>
                {menuLink.map((value, index) => (
                  <div className="">
                    <ListItem
                      className="header-link-color text-decoration-none header-link"
                      key={index}
                      button
                      onClick={() => onClick(value.url)}
                    >
                      <ListItemText
                        className="accordian-list-item"
                        primary={value.label}
                      />
                    </ListItem>
                  </div>
                ))}
              </List>
            )
          ) : (
            <List>
              {menuRegister.map((value, index) => (
                <div className="">
                  <ListItem
                    className="header-link-color text-decoration-none header-link"
                    key={index}
                    button
                    onClick={() => onClick(value.url)}
                  >
                    <ListItemText primary={value.label} />
                  </ListItem>
                </div>
              ))}
            </List>
          )}
        </Box>
      </Drawer>
    </>
  );
};

export default MenuDrawer;
