import React from 'react';
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import './Loading.scss';

export default function Loading() {
    return (
        <div className="Loading">
            <CircularProgress />
        </div>
    );
}
