import { useEffect } from 'react';
import AuthHelper from './AuthHelper';


const HotjarUserTracker = () => {
    const auth = new AuthHelper();
    const userDetail = auth.getUserDetails();
  useEffect(() => {
    if (window.hj && userDetail) {
      window.hj('identify', userDetail.id, {
        email: userDetail.email,
        first_name: userDetail.first_name,
        last_name: userDetail.last_name,
        // Add any other user properties you want to track
      });
      console.log('Hotjar initialized');
    }
    
  }, [userDetail]); // Effect hook dependency on user object

  return null; // This component does not render anything
};

export default HotjarUserTracker;