import React, { useEffect, useRef, useState } from "react";
import { useMount } from "ahooks";
import {
  Button,
  CircularProgress,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import "./index.scss";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { isArray, isEmpty } from "lodash";
import {
  BackButton,
  BreadcrumbsClass,
  ButtonContainer,
  EmptyDiv,
  FormContainerClass,
  LabelClass,
  OnboardingStepContainerClass,
  TitleClass,
  NextButton,
} from "../shared/BootStrapStyling";
import { makeStyles } from "@material-ui/styles";
import OnboardingBreadcrumbs from "../shared/OnboardingBreadcrumbs";
import {
  getNewCities,
} from "../../../shared/apis/ConfigService";
import { getProfileInfo } from "../../../shared/apis/UserService";
import { useTheme } from "@material-ui/core/styles";
import { VariableSizeList } from "react-window";
import { Typography } from "@material-ui/core";
import PropTypes from "prop-types";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import ListSubheader from "@material-ui/core/ListSubheader";
import { allowIntegersOnly } from "../../../shared/helpers/utils";
import { industry2 } from "../../../shared/apis/OnboardingService";

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});
const filterOptions = createFilterOptions({
  matchFrom: "start",
  stringify: (option) => option.name,
});

const useStyles = makeStyles((theme) => ({
  listbox: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
}));

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node,
};

const renderGroup = (params) => [
  <ListSubheader key={params.key} component="div">
    {params.group}
  </ListSubheader>,
  params.children,
];

const StepFive = ({
  defaultValue,
  industries,
  onBack,
  onSubmit,
}) => {
  const { control, errors, handleSubmit, setValue, trigger } = useForm({
    mode: "onBlur",
    defaultValues: {
      userIndustries: defaultValue?.user_industries ?? [],
      targetCompanyEarningsMin: defaultValue?.target_company_earnings?.[0] ?? "",
      targetCompanyEarningsMax: defaultValue?.target_company_earnings?.[1] ?? "",
      targetCompanyEarningsType: defaultValue?.target_company_earnings_type ?? null,
      targetCompanyPriceMin: defaultValue?.target_company_price?.[0] ?? "",
      targetCompanyPriceMax: defaultValue?.target_company_price?.[1] ?? "",
      locations: defaultValue?.location ?? [],
    }
  });
  const classes = useStyles();

  
  /* Select menu options */
  const [industriesOptions, setIndustriesOptions] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [userInfo, setUserInfo] = useState();
  const [userIndustries, setUserIndustries] = useState(null);
  const [newCitiesOptions, setNewCitiesOptions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const IsSubmitted = useRef(false);

  const fetchUserInfo = async () => {
    try {
      let userInfoData;

      userInfoData = await getProfileInfo();
      setUserInfo(userInfoData);
      setUserIndustries(userInfoData.industries_experience);
    } catch (e) {
      console.log(e);
    }
  };
  const fetchNewCitiesOptions = async () => {
    try {
      const response = await getNewCities();
      setNewCitiesOptions(response);
    } catch (e) {
      setNewCitiesOptions([]);
    }
  };
  useEffect(() => {
    fetchUserInfo();
    fetchNewCitiesOptions();
  }, []);

  useMount(() => {
    setNewCitiesOptions(newCitiesOptions ?? []);
    if (userIndustries && userIndustries.length) {
      setValue("userIndustries", userIndustries);
    } else {
      setValue(
        "userIndustries",
        isEmpty(defaultValue?.user_industries)
        ? []
        : defaultValue.user_industries
        );
      }
      
    });
    
  useEffect(() => {
    setIndustriesOptions(industries ?? []);
  },[industries]);

  /* When country is selected, fetch provinces */
  

  const onSubmitStepFive = async (data) => {
    const values = {
      ...defaultValue,
      desired_business_country: data.desiredCountry,
      desired_business_province: data.desiredProvince,
      desired_business_city: data.desiredCity,
      target_company_earnings: [
        Number(data.targetCompanyEarningsMin),
        Number(data.targetCompanyEarningsMax),
      ],
      target_company_earnings_type: data.targetCompanyEarningsType,
      target_company_price: [Number(data.targetCompanyPriceMin), Number(data.targetCompanyPriceMax)],
      user_industries: data.userIndustries,
      location: data.locations?.map((item) => item.id) ?? [],
    };
    if (IsSubmitted.current) {
      try {
        setIsSubmitting(true);
        await industry2(values);
        setIsSubmitting(false);
        onSubmit(values);
      } catch (e) {
        setIsSubmitting(false);
      }
    } else {
      onBack(values);
    }
  };
  const onClickBack = () => {
    IsSubmitted.current = false;
  };

  const onClickSubmit = () => {
    IsSubmitted.current = true;
  };

  useEffect(() => {
    const getLocationsByIds = (ids) => {
      return ids.map((id) => {
        return findOptionObj(newCitiesOptions, id);
      });
    }
    if (defaultValue?.location?.length) {
      setValue("locations", getLocationsByIds(defaultValue?.location));
    }
  }, [newCitiesOptions, setValue, defaultValue?.location]);

  return (
    <div className={`${OnboardingStepContainerClass}`}>
      <div className={`${BreadcrumbsClass}`}>
        <OnboardingBreadcrumbs currentStep={3} />
      </div>
      <form
        id="stepFive"
        autoComplete="off"
        onSubmit={handleSubmit(onSubmitStepFive)}
        className={`${FormContainerClass}`}
      >
        <div>
          <h1 className={`${TitleClass}`}>Search Criteria</h1>
        </div>
        <label className={`${LabelClass}`}>Desired Price Range</label>
        <div className="d-flex w-100">
          <div className="w-100">
            <Controller 
              name="targetCompanyPriceMin"
              control={control}
              rules={{ 
                required: "Minimum desired company price is required",
                validate: {
                  lessThanMax: (value) => {
                    if (value && control.getValues()?.targetCompanyPriceMax && value > 0 && value >= Number(control.getValues()?.targetCompanyPriceMax)) {
                      return "Minimum desired company price must be less than maximum desired company price";
                    }
                    return true;
                  }
                }
              }}
              render={({ onChange, value, ref }) => (
                <TextField
                  name="targetCompanyPriceMin"
                  placeholder="e.g. 1,000,000"
                  fullWidth
                  variant="outlined"
                  value={value}
                  onChange={(e) => {
                     onChange(e.target.value)
                     trigger(["targetCompanyPriceMin","targetCompanyPriceMax"])
                  }}
                  onKeyDown={allowIntegersOnly}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  inputRef={ref}
                />
              )}
            />
            <ErrorMessage
              className="error-message mt-1"
              errors={errors}
              name="targetCompanyPriceMin"
              as="p"
            />
          </div>
          <div className="font-semi-bold px-2 to-separator">To</div>
          <div className="w-100">
            <Controller 
              name="targetCompanyPriceMax"
              control={control}
              rules={{ 
                required: "Maximum desired company price is required",
                validate: {
                  greaterThanMin: (value) => {
                    if (value && control.getValues()?.targetCompanyPriceMin  && value > 0 && value <= Number(control.getValues()?.targetCompanyPriceMin)) {
                      return "Maximum desired company price must be greater than minimum desired company price";
                    }
                    return true;
                  }
                }
              }}
              render={({ onChange, value, ref }) => (
                <TextField
                  name="targetCompanyPriceMax"
                  placeholder="e.g. 2,000,000"
                  fullWidth
                  variant="outlined"
                  value={value}
                  onChange={(e) => { 
                    onChange(e.target.value)
                    trigger(["targetCompanyPriceMin","targetCompanyPriceMax"])
                  }}
                  onKeyDown={allowIntegersOnly}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  inputRef={ref}
                />
              )}
            />
            <ErrorMessage
              className="error-message mt-1"
              errors={errors}
              name="targetCompanyPriceMax"
              as="p"
            />
          </div>
        </div>
        <div className="d-flex-column align-items-center w-100 py-3">
          <label className={`${LabelClass}`}>Desired Earnings</label>
          <div>
            <Controller
              name="targetCompanyEarningsType"
              control={control}
              rules={{ required: "This field is required. Choose one option to proceed" }}
              render={({ onChange, value, ref }) => (
                <RadioGroup
                  name="targetCompanyEarningsType"
                  row
                  onChange={(e) => onChange(Number(e.target.value))}
                  value={value}
                  inputRef={ref}
                >
                  <FormControlLabel
                    value={0}
                    control={<Radio color="primary" />}
                    label="EBITDA"
                  />
                  <FormControlLabel
                    value={1}
                    control={<Radio color="primary" />}
                    label="SDE"
                  />
                </RadioGroup>
              )}
            />
          </div>
          <ErrorMessage
            className="error-message"
            errors={errors}
            name="targetCompanyEarningsType"
            as="p"
          />
          <div className="d-flex">
            <div className="w-100">
              <Controller
                name="targetCompanyEarningsMin"
                control={control}
                rules={{ 
                  required: "Minimum desired company earnings is required",
                  validate: {
                    lessThanMax: (value) => {
                      if (value && control.getValues()?.targetCompanyEarningsMax && value > 0 && value >= Number(control.getValues()?.targetCompanyEarningsMax)) {
                        return "Minimum desired company earnings must be less than maximum desired company earnings";
                      }
                      return true;
                    }
                  } 
                }}
                render={({ onChange, value, ref }) => (
                  <TextField
                    name="targetCompanyEarningsMin"
                    variant="outlined"
                    fullWidth
                    placeholder="e.g. 250,000"
                    onChange={(e) => { 
                      onChange(e.target.value)
                      trigger(["targetCompanyEarningsMax","targetCompanyEarningsMin"]);
                    }}
                    value={value}
                    onKeyDown={allowIntegersOnly}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    inputRef={ref}
                  />
                )}
              />
              <ErrorMessage
                className="error-message mt-1"
                errors={errors}
                name="targetCompanyEarningsMin"
                as="p"
              />
            </div>
            <div className="font-semi-bold px-2 to-separator">To</div>
            <div className="w-100">
              <Controller
                name="targetCompanyEarningsMax"
                control={control}
                rules={{ 
                  required: "Maximum desired company earnings is required",
                  validate: {
                    greaterThanMin: (value) => {
                      if (value && control.getValues()?.targetCompanyEarningsMin && value > 0 && value <= Number(control.getValues()?.targetCompanyEarningsMin)) {
                        return "Maximum desired company earnings must be greater than minimum desired company earnings";
                      }
                      return true;
                    }
                  }
                }}
                render={({ onChange, value, ref }) => (
                  <TextField
                    name="targetCompanyEarningsMax"
                    variant="outlined"
                    fullWidth
                    placeholder="e.g. 500,000"
                    onChange={(e) => { 
                      onChange(e.target.value)
                      trigger(["targetCompanyEarningsMin","targetCompanyEarningsMax"]);
                    }}
                    value={value}
                    onKeyDown={allowIntegersOnly}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    inputRef={ref}
                  />
                )}
              />
              <ErrorMessage
                className="error-message mt-1"
                errors={errors}
                name="targetCompanyEarningsMax"
                as="p"
              />
            </div>
          </div>
        </div>
        <div className="d-lg-flex d-flex-column pb-4 pt-1 w-100 py-3">
          <div className="w-100">
            <label className={`${LabelClass}`}>
              Select up to 5 industries of interest
            </label>
            <Controller
              name="userIndustries"
              control={control}
              rules={{
                validate: {
                  notEmpty: (value) => {
                    if (isArray(value) && value.length > 5) {
                      return "You can only select up to 5 industries";
                    } else if (isArray(value) && value.length < 1) {
                      return "Select at least one industry";
                    }
                    return true;
                  }
                }
              }}
              render={(props) => (
                <Select
                  multiple
                  displayEmpty
                  disabled={isEmpty(industriesOptions)}
                  className="w-100"
                  variant="outlined"
                  defaultValue={!isEmpty(props.value) ? props.value : []}
                  inputProps={{ "aria-label": "multi industry select" }}
                  renderValue={() => {
                    if (isEmpty(props.value)) return <em>Select Industries</em>;
                    return mapIdToName(props.value, industriesOptions);
                  }}
                  {...props}
                  value={!isEmpty(props.value) ? props.value : []}
                >
                  <MenuItem disabled value="">
                    <em>{"Select Industries"}</em>
                  </MenuItem>
                  {industriesOptions?.map((industry) => (
                    <MenuItem
                      value={industry.id}
                      key={industry.name + industry.id}
                    >
                      {industry.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            <ErrorMessage
              className="error-message mt-1"
              errors={errors}
              name="userIndustries"
              as="p"
            />
          </div>
        </div>

        <div className="w-100 col-lg-8 col-lg-12 col-12 p-0 m-1">
          <label className={`${LabelClass}`} id="locations">
            What cities are you searching in? 
          </label>
          <Controller
            name="locations"
            control={control}
            rules={{
              validate: {
                notEmpty: (value) => {
                  if (isArray(value) && value.length > 5) {
                    return "You can only select up to 10 locations";
                  } else if (isArray(value) && value.length < 1) {
                    return "Select at least one location";
                  }
                  return true;
                }
              }
            }}
            render={({ onChange, value, ref }) => (
              <Autocomplete
                id="virtualize-demo"
                filterOptions={(options, params) => {
                  if (params.inputValue === '') {
                    return [];
                  }
                  return filterOptions(options, params);
                }}
                disableListWrap
                openOnFocus={false}
                freeSolo
                disabled={isEmpty(newCitiesOptions)}
                loading={isEmpty(newCitiesOptions)}
                popupIcon={false}
                disableClearable
                classes={classes}
                ListboxComponent={ListboxComponent}
                renderGroup={renderGroup}
                options={newCitiesOptions}
                getOptionLabel={(option) => option?.name && option?.state_code ? option?.name + ", " + option?.state_code: "..."}
                multiple={true}
                onChange={(event, newValue) => onChange(newValue)}
                value={value}
                inputProps={{ "aria-label": "location select" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    helperText="Please select between 1 and 10 locations"
                    variant="outlined"
                    label="Type a location"
                  />
                )}
                renderOption={(option) => (
                  <Typography noWrap>
                    {option.name + ", " + option.state_code}
                  </Typography>
                )}
                ref={ref}
              />
            )}
          />
          <ErrorMessage
            className="error-message mt-1"
            errors={errors}
            name="locations"
            as="p"
          />
        </div>
        <div className={`${ButtonContainer}`}>
          <div>
            <Button
              startIcon={<ArrowBackIcon />}
              type="submit"
              onClick={onClickBack}
              className={`${BackButton}`}
            >
              {"Previous"}
            </Button>
          </div>
          <div>
            <Button
              type="submit"
              onClick={onClickSubmit}
              className={`${NextButton}`}
              disabled={isSubmitting}
            >
              { isSubmitting ? <CircularProgress color="secondaryTextColor" size="1.9rem" /> : "Next" }
            </Button>
          </div>
          <div className={`${EmptyDiv}`}>
            {/*this element completes the three column layout and allows the 'next' button to be centered*/}
          </div>
        </div>
      </form>
    </div>
  );
};

const findOptionObj = (options, value) => {
  return options.find((option) => value === option.id);
};

const mapIdToName = (selectedValue, options) => {
  const currentSelections = selectedValue?.map(
    (value) => findOptionObj(options, value)?.name
  );

  return currentSelections.join(", ");
};

StepFive.defaultProps = {};

StepFive.propTypes = {};

export default StepFive;
