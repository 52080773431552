import React, { useEffect, useRef, useState } from 'react'
import { useMount } from 'ahooks'
import { isEmpty } from "lodash"
import { Controller, useForm } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import {
  Button, CircularProgress, MenuItem, Select, TextField, Tooltip
} from '@material-ui/core'
import { HelpOutline, RemoveRedEyeOutlined } from "@material-ui/icons"

import '../index.scss'
import {
  BreadcrumbsClass, FormContainerClass, LabelClass,
  OnboardingStepContainerClass, TitleClass
} from "../shared/BootStrapStyling"
import {BUSINESS_ROLES, BUYER_PROFILE_TYPES} from "../../../shared/constants"

import OnboardingBreadcrumbs from "../shared/OnboardingBreadcrumbs"
import { getLookupCitiesByProvince, getLookupProvinces } from "../../../shared/apis/ConfigService"
import { aboutYou1 } from '../../../shared/apis/OnboardingService'

const StepOne = ({ countries, defaultValue, onBack, onSubmit }) => {
  const { control, errors, handleSubmit, setValue, watch } = useForm({ mode: 'onSubmit' })

  const watchCountry = watch('country')
  const watchProvince = watch('province')

  const IsSubmitted = useRef(false)

  const [countriesOptions, setCountriesOptions] = useState([])
  const [provincesOptions, setProvincesOptions] = useState([])
  const [citiesOptions, setCitiesOptions] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)

  useMount(() => {
    setCountriesOptions(countries ?? [])
    setValue('expertise', defaultValue?.expertise ?? '')
    setValue('describeYou', defaultValue?.buyer_type ?? '')
    setValue('firstname', defaultValue?.first_name ?? '')
    setValue('lastname', defaultValue?.last_name ?? '')
    setValue('country', defaultValue?.country ?? '41')
    setValue('province', defaultValue?.province ?? '')
    setValue('city', defaultValue?.city ?? '')
  });

  /* When country is selected, fetch provinces */
  useEffect(() => {
    if (watchCountry) {
      getProvincesOptions(watchCountry)
    }
  }, [watchCountry])

  /* When province is selected, fetch cities */
  useEffect(() => {
    if (watchProvince) {
      getCitiesOptions(watchProvince)
    }
  }, [watchProvince])

  const onFormSubmit = async (data) => {
    const updateValues = {
      ...defaultValue,
      first_name: data.firstname ? String(data.firstname).trim(): '',
      last_name: data.lastname ? String(data.lastname).trim(): '',
      buyer_type: data.describeYou,
      expertise: data.expertise,
      country: data.country,
      province: data.province,
      city: data.city,
    }

    if (IsSubmitted.current) {
      setIsSubmitting(true)
      try {
        await aboutYou1(updateValues)
        setIsSubmitting(false)
        onSubmit(updateValues);
      } catch (error) {
        setIsSubmitting(false)
      }
    } else {
      onBack(updateValues);
    }
  };

  const onClickSubmit = () => {
    IsSubmitted.current = true;
  };

  const getProvincesOptions = async (countryId) => {
    try {
      const { data } = await getLookupProvinces(countryId)
      setProvincesOptions(data)
    }
    catch (e) {
      console.log(e)
    }
  }

  const getCitiesOptions = async (provId) => {
    try {
      const { data } = await getLookupCitiesByProvince(provId)
      setCitiesOptions(data)
    }
    catch (e) {
      console.log(e)
    }
  }

  return (
    <div className={`${OnboardingStepContainerClass}`}>
      <div className={`${BreadcrumbsClass}`}><OnboardingBreadcrumbs currentStep={0} /></div>
      <form
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit(onFormSubmit)}
        className={`${FormContainerClass}`}
      >
        <div>
          <h1 className={`${TitleClass}`}>
            {'Let’s grab some personal details.'}
          </h1>
        </div>
        <div className='fields row'>
          <div className="col-12 col-lg-6">
            <label className={`${LabelClass}`} htmlFor='firstname'>
              {'First Name'}
            </label>
            <Controller
              name="firstname"
              control={control}
              defaultValue={''}
              rules={{ validate: value => value.trim().length > 0 || "First Name field is required"}}
              render={(props) => (
                <TextField {...props} id="filled-size-normal" variant='outlined' className='w-100' placeholder='e.g. John' />
              )}
            />
            <ErrorMessage className="error-message mt-1" errors={errors} name="firstname" as="p" />
          </div>

          <div className="col-12 col-lg-6 hide-lastname py-2 py-lg-0">
            <div className='lastname'>
              <label className={`${LabelClass}`} htmlFor='lastname'>
                {'Last Name'}
              </label>
              <Controller
                name="lastname"
                control={control}
                defaultValue={''}
                rules={{ validate: value => value.trim().length > 0 || "Last Name field is required"}}
                render={(props) => (
                  <TextField {...props} id="filled-size-normal" variant='outlined' className='w-100' placeholder='e.g. Smith' />
                )}
              />
              <ErrorMessage className="error-message mt-1" errors={errors} name="lastname" as="p" />
            </div>
          </div>
        </div>
        <div className='d-flex-column lg:d-flex-row align-items-center w-100 pb-4 pt-1 px-1'>
          <RemoveRedEyeOutlined fontSize='small' />
          <small style={{color: '#7D7F80', fontFamily: 'Inter-Bold' }} className='text-muted px-2'> Your full name will not be displayed in searchable profile</small>
        </div>
        <div className='w-100 col-lg-8 col-lg-12 col-12 p-0 m-1'>
          <span>
          <label className={`${LabelClass}`} >Where are you located?<Tooltip title="If located outside of Canada or US select primary search location" placement="top" className='pb-1'><HelpOutline/></Tooltip></label>
          </span>
          <div className='d-lg-flex d-flex-column justify-content-around'>
            <div className='w-100 '>
              <Controller
                name="country"
                control={control}
                defaultValue={''}
                rules={{ required: "Country selection is required" }}
                render={(props) => (
                  <Select
                    displayEmpty
                    disabled={isEmpty(countriesOptions)}
                    className="w-100"
                    variant='outlined'
                 
                 
                    inputProps={{ 'aria-label': 'location select' }}
                    {...props}
                  >
                    <MenuItem disabled value="" defaultValue={1}><em>{'Select Country'}</em></MenuItem>
                    {countriesOptions?.map(country => (
                      <MenuItem value={country.id} key={country.name + country.id}>{country.name}</MenuItem>
                    ))}
                  </Select>
                )}
              />
              <ErrorMessage className="error-message mt-1" errors={errors} name="country" as="p" />
            </div>
            <div className='mx-lg-2 my-2 my-lg-0 w-100'>
              <Controller
                name="province"
                control={control}
                defaultValue={''}
                rules={{ required: "Province selection is required" }}
                render={(props) => (
                  <Select
                    displayEmpty
                    disabled={isEmpty(provincesOptions)}
                    className="w-100"
                    variant='outlined'
                    inputProps={{ 'aria-label': 'location select' }}
                    {...props}
                  >
                    <MenuItem disabled value=""><em>{'Select Province or State'}</em></MenuItem>
                    {provincesOptions?.map(prov => (
                      <MenuItem value={prov.id} key={prov.name + prov.id}>{prov.name}</MenuItem>
                    ))}
                  </Select>
                )}
              />
              <ErrorMessage className="error-message mt-1" errors={errors} name="province" as="p" />
            </div>
            <div className='w-100'>
              <Controller
                name="city"
                control={control}
                defaultValue={''}
                rules={{ required: "City selection is required" }}
                render={(props) => (
                  <Select
                    displayEmpty
                    disabled={isEmpty(citiesOptions)}
                    className="w-100"
                    variant='outlined'
                    inputProps={{ 'aria-label': 'location select' }}
                    {...props}
                  >
                    <MenuItem disabled value=""><em>{'Select City'}</em></MenuItem>
                    {citiesOptions?.map(city => (
                      <MenuItem value={city.id} key={city.name + city.id}>{city.name}</MenuItem>
                    ))}
                  </Select>
                )}
              />
              <ErrorMessage className="error-message mt-1" errors={errors} name="city" as="p" />
            </div>
          </div>
          <div className='d-flex-column lg:d-flex-row align-items-center w-100 pb-4 px-1'>
            <RemoveRedEyeOutlined fontSize='small' />
            <small style={{color: '#7D7F80;', fontFamily: 'Inter-Bold' }} className='text-muted px-2'> Not displayed in searchable profile</small>
          </div>
        </div>
        <div className='w-100 col-lg-8 col-lg-12 col-12 p-0 m-1 pb-4'>
          <label className={`${LabelClass}`} id="describe-you">What type of buyer are you?</label>
          <div>
            <Controller
              name="describeYou"
              control={control}
              defaultValue={''}
              rules={{ required: "Buyer type selection is required" }}
              render={(props) => (
                <Select aria-labelledby="describe-you" displayEmpty variant='outlined' className='w-100' {...props}>
                  <MenuItem disabled value=""><em>{'Select Type'}</em></MenuItem>
                  {BUYER_PROFILE_TYPES.map((item) => (
                    <MenuItem value={item.value} key={item.label}>{item.label}</MenuItem>
                  ))}
                </Select>
              )}
            />
          </div>
          <ErrorMessage className="error-message mt-1" errors={errors} name="describeYou" as="p" />
        </div>
        <div className='pb-4'>
          <label className={`${LabelClass}`} id="role">What role will you play in your acquisition?</label>
          <div>
            <Controller
              name="expertise"
              control={control}
              defaultValue={''}
              rules={{ required: "Acquisition role selection is required" }}
              render={(props) => (
                <Select aria-labelledby="expertise-role" displayEmpty variant='outlined' className='w-100' {...props}>
                  <MenuItem disabled value=""><em>{'Select Role'}</em></MenuItem>
                  {BUSINESS_ROLES.map((item) => (
                    <MenuItem value={item.value} key={item.label}>{item.label}</MenuItem>
                  ))}
                </Select>
              )}
            />
          </div>
          <ErrorMessage className="error-message mt-1" errors={errors} name="expertise" as="p" />
        </div>
        <div className='form-controls d-flex justify-content-center'>
          <Button 
            type='submit' 
            onClick={onClickSubmit} 
            className="h-auto w-25 p-2 border nextBtn" 
            disabled={isSubmitting}
          >
            { isSubmitting ? <CircularProgress color="secondaryTextColor" size="1.9rem" /> :  'Next' }
          </Button>
        </div>
      </form>
    </div>
  );
};

StepOne.defaultProps = {};

StepOne.propTypes = {};

export default StepOne;
