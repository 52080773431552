import {get} from "lodash";
import UserIcon from 'assets/images/individual_icon.png';
import UsersIcon from 'assets/images/group_icon.png';
import BankIcon from 'assets/images/private_equity_icon.png';
import HandshakeIcon from 'assets/images/co_op_icon.png';
import CompanyIcon from 'assets/images/existing_organization_icon.png';
import VolunteerIcon from 'assets/images/nonprofit_icon.png';

export const formatNumberWithCommas = (x) => {
    if(x !== null && x !== undefined) {
        return x.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    }
}

export const getUserIcon = (profile) => {
    let icon;
    let buyerTypeLongName;
    const buyerType = get(profile, 'buyer_type', '');
    switch (buyerType) {
        case 'In':
            icon = UserIcon;
            buyerTypeLongName = "Individual Buyer";
            break;
        case 'GI':
            icon = UsersIcon;
            buyerTypeLongName = "Group of Individuals";
            break;
        case 'EO':
            icon = CompanyIcon;
            buyerTypeLongName = "Existing Organization";
            break;
        case 'NP':
            icon = VolunteerIcon;
            buyerTypeLongName = "Non-Profit Organization";
            break;
        case 'PE':
            icon = BankIcon;
            buyerTypeLongName = "Private Equity";
            break;
        case 'CP':
            icon = HandshakeIcon;
            buyerTypeLongName = "Co-op";
            break;
        default:
            icon = UserIcon;
            break;
    }
    return {icon, buyerTypeLongName};
}

export const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: 'long', day: 'numeric', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate;
}

/**
 * Allow only integers to be entered in an input field
 * @param {Event} event - The event object from the input field
 * @returns {void} - No return
 * @example onKeyDown={(e) => allowIntegersOnly(e)}
 * @example onKeyDown={allowIntegersOnly}
 */
export const allowIntegersOnly = (event) => {
    // Allow: backspace, delete.
    if ([46, 8].includes(event.keyCode) ||
        // Allow: Ctrl+A, Command+A
        (event.keyCode === 65 && (event.ctrlKey === true || event.metaKey === true)) ||
        // Allow: home, end, left, right, down, up
        (event.keyCode >= 35 && event.keyCode <= 40)) {
        // let it happen, don't do anything
        return;
    }

    // If it's a number, allow
    if (/[0-9]/.test(event.key)) return;

    // Otherwise, stop the keypress
    event.preventDefault();
}
