import React from "react"

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import Link from '@material-ui/core/Link';
import { makeStyles} from "@material-ui/core";
import {withRouter} from "react-router-dom";




const useStyles = makeStyles({
    divider: {background: '#f1e9d7'},
    socialIcon: {width: 16, height: 16},
    noButton: {
        backgroundColor: 'transparent',
        border: '1px solid',
        marginRight: '30px'
    },
    button1: {
        textTransform: 'none',
        textAlign: 'center',
        color : '#FFFFFF',
        backgroundColor:'#558BB9',
        borderRadius: '4px !important',
        border :'0px',
        fontFamily:"Inter-SemiBold",
        fontSize : '15px',
        letterSpacing : '0px',
        "&:hover":{
            backgroundColor:'#5687b0',
        }
    },
    input : {
        'background': 'transparent',
        'color' :'#558BB9',
        'border': '#558BB9 solid 1px',
        'border-radius': '4px',
        'padding': '0px 20px',
        margin: '0px  10px 0px 0px',
        opacity:'1',
    },
    bottomFotter : {
        background : '#324F61',
        height : '50px'
    },
    leading :  {
        fontSize : '13px',
        letterSpacing : '0px',
        color: '#324F61',
        width: '353px',
    },

});

const Footer = (props) => {
    const classes = useStyles();
    
    return (
        <div className="footer mt-2">
            <div className="d-flex flex-row justify-content-around p-4 footer-subcontainer">
                <div className="w-50 footer-subscribe-section">
                    
                </div>
                <div className="w-100 w-md-50 d-flex flex-row justify-content-end pt-2 mt-2 mt-md-5 px-2 footer-links-0">
                    <div className="mobile-border-top w-100 w-md-25 mb-4 pt-4 pt-md-0 mb-md-0 mx-0 mx-md-4 mr-5 footer-links-1">
                 

                        <div className="d-block ">
                            <Link className='u-color-text font-semi-bold w-auto footer footer-contact-us' href="/buyers">
                            Search Buyers
                            </Link>
                        </div>

                        <div className="d-block pt-2">
                            <Link className='u-color-text font-semi-bold w-auto footer footer-contact-us' href="https://villagewellth.com/financing/">
                            Financing
                            </Link>
                        </div>

                        <div className="d-block pt-2">
                            <Link className='u-color-text font-semi-bold w-auto footer footer-contact-us' href="http://villagewellth.com/blog/">
                            Resources
                            </Link>
                        </div>
                    </div>
                    <div className="w-100 mobile-border-top w-md-25 footer-links-2 mb-4 pt-4 pt-md-0">
                        <div className="d-block">
                                <Link className='u-color-text font-semi-bold w-auto footer footer-contact-us' href="/contact-us">
                                        Contact Us
                                </Link>
                                </div>
                                <div className="d-block pt-2">
                                    <a className='u-color-text font-semi-bold  footer footer-contact-us' href="/terms-of-use">
                                        Terms of Use
                                    </a>
                                </div>
                                <div className="d-block pt-2">
                                    {/* eslint-disable-next-line */}
                                    <a href="https://www.villagewellth.com/privacy-policy" className="u-color-text font-semi-bold  footer footer-contact-us" target="_blank">Privacy Policy</a>
                                </div>
                        </div>
                </div>
            </div>
            <div className={`${classes.bottomFotter} d-flex flex-row justify-content-between align-items-center`}>
                <p className={`u-white-somke-color ml-4 mb-0 footer-vw-copyright font-regular`}>&copy; Village Wellth {new Date().getFullYear()}</p>
                <div className="d-flex flex-row me-4">
                        <Link className='u-white-somke-color' href="https://www.instagram.com/villagewellth/?hl=en" target="_blank">
                            <InstagramIcon className={`ml-3 mr-3 ${classes.socialIcon}`}></InstagramIcon>
                        </Link>
                        <Link className='u-white-somke-color' href="#" target="_blank">
                            <TwitterIcon  className={`mr-3 ${classes.socialIcon}`}></TwitterIcon>
                        </Link>
                        <Link className='u-white-somke-color' href="https://www.facebook.com/VillageWellth/" target="_blank">
                            <FacebookIcon className={`mr-3 ${classes.socialIcon}`} ></FacebookIcon>
                        </Link>

                    </div>
            </div>
        </div>
    )
}
export default withRouter(Footer);
