export const local = {
  apiUrl: "http://localhost:8000/",
  //  apiUrl: "https://alpaca.villagewellthstaging.staging.c66.me/",
  webUrl: "localhost",
};

export const dev = {
  // https://dev2.api.villagewellth.com/
  apiUrl: "https://dev-api.villagewellth.com/",
  webUrl: "dev.villagewellth.com",
};

export const staging = {
  // https://dev2.api.villagewellth.com/
  // apiUrl: "https://alpaca.villagewellthstaging.staging.c66.me/",
  // apiUrl: "https://prod-api.villagewellth.com/",
  //New Staging URL
  apiUrl: "https://stage-api.villagewellth.com/",
  webUrl: "staging.villagewellth.com",
};

export const prod = {
  // apiUrl: "https://api.villagewellth.com/",
  apiUrl: "https://prod-api.villagewellth.com/",
  webUrl: "villagewellth.com",
};

const getConfig = function () {
  if (process.env.API_URL) {
    return process.env.API_URL;
  }

  if (window.location.hostname.indexOf(local.webUrl) > -1) {
    return local;
  }

  if (window.location.hostname.indexOf(dev.webUrl) > -1) {
    return dev;
  }

  if (window.location.hostname.indexOf(staging.webUrl) > -1) {
    return staging;
  }

  if (window.location.hostname.indexOf(prod.webUrl) > -1) {
    return prod;
  }
  console.log('host', window.location.hostname);
  return prod;
};

export { getConfig as default };
