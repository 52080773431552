import axios from 'axios';
import getConfig from "../config";
import {toast} from "react-toastify";

export const API_URL = getConfig().apiUrl;

const handleErrors = err => {
  if (err && err.response) {
      if (err.response.status === 401) {
          window.localStorage.setItem('jwt', undefined);
      } else if (err.response.status === 400 && err.response.data && err.response.data.errors) {
          let errors = err.response.data.errors;
          if (!Array.isArray(errors)) {
              errors = Object.values(errors)
          }

          errors.forEach( el => {
              if (Array.isArray(el)) {
                  el.forEach( error => {
                      if (error) {
                          toast.error(error);
                      }
                  });
              } else {
                  toast.error(el);
              }
          });
      }
  }
  console.log(err);
  return Promise.reject(err);
};

const axiosInstance = axios.create({
  baseURL: `${API_URL}`,
  timeout: 300000,
});

axiosInstance.interceptors.request.use((config) => {
  if (window.localStorage.getItem('jwt')) {
    config.headers.Authorization = `Token ${window.localStorage.getItem('jwt')}`;
  }

  return config;
}, function(err) {
  return Promise.reject(err);
});


axiosInstance.interceptors.response.use(function (response) {
  // Do something with response data
  return response;
}, handleErrors);

export default axiosInstance;
