import API from "./ApiBase";
import _ from "lodash";

export const getBuyers = async (
  pageNumber = undefined,
  filter = undefined,
  sortType = undefined
) => {
  let params = {
    role: "Br",
  };
  if (sortType) {
    params["sort_by"] = sortType;
  }
  if (pageNumber) {
    params = {
      ...params,
      page: pageNumber,
      page_size: 24,
    };
  }
  if (filter) {
    // console.log('buyerservice', filter)
    filter = _.pickBy(filter, (value, key) => {
      if (Array.isArray(value) && _.includes(value, "")) {
        value = [];
      }
      return !(
        value === undefined ||
        value === "" ||
        (Array.isArray(value) && value.length === 0)
      );
    });
    params = {
      ...params,
      filter,
    };
    // console.log(params)
  }
  return await API.get("/api/v1/user/", { params });
};

export const getFeaturedBuyers = async (
  pageNumber = undefined,
  pageSize = 9,
  filter = undefined
) => {
  let params = {
    role: "Br",
    is_featured: true,
  };
  if (pageNumber) {
    params = {
      ...params,
      page: pageNumber,
      page_size: pageSize,
    };
  }
  if (filter) {
    filter = _.pickBy(filter, (value, key) => {
      if (Array.isArray(value) && _.includes(value, "")) {
        value = [];
      }
      return !(
        value === undefined ||
        value === "" ||
        (Array.isArray(value) && value.length === 0)
      );
    });
    params = {
      ...params,
      filter,
    };
  }
  return await API.get("/api/v1/user/", { params });
};

export const getFavoredBuyers = async (
  pageNumber = undefined,
  sortType = undefined
) => {
  let params = {
    role: "Br",
    favored: true,
  };
  if (sortType) {
    params["sort_by"] = sortType;
  }
  if (pageNumber) {
    params = {
      ...params,
      page: pageNumber,
      page_size: 9,
    };
  }
  return await API.get("/api/v1/user/", { params });
};

export const connectionRequests = async (params) => {

  return await API.get(`/api/v1/buyers/shared-requests/`, { params });
};

export const deleteSharedOpportunity = async (id) => {
  return await API.delete(`/api/v1/buyers/deleteSharedOpportunity/${id}`);
}

// This removes all connection requests associated with a seller opportunity that has been removed
export const deleteSharedOpportunities = async (id) => {
  return await API.delete(`/api/v1/buyers/deleteSharedOpportunities/${id}`);
}

export const acceptRequest = async (data) => {
  return await API.post(`/api/v1/buyers/accept-request/`, data);
};

export const getMessages = async (data) => {
  return await API.get(`/api/v1/buyers/unread-messages/`, data);
}

export const buyerConversationFeedback = async (data) => {
  return await API.post(`/api/v1/feedback/buyer-conversation`, data);
}

export const buyerDeclineRequestFeedback = async (data) => {
  return await API.post(`/api/v1/feedback/buyer-decline-request`, data);
}

export const createPublicRequest = async (data) => {
  return await API.post(`/api/v1/buyers/create-request/`, data);
};

export const publicConnectionRequests = async (id) => {

  return await API.get(`/api/v1/buyers/shared-opportunity-check/${id}`);
};

export const taskList = async (id) => {

  return await API.get(`/api/v1/tasks/${id}`);
};

export const updateTask = async (id, data) => {

    return await API.put(`/api/v1/tasks/${id}/status`, data);
  }

export const lightDiligence = async (id) => {
  
    return await API.get(`/api/v1/tasks/light-diligence/${id}`);
  }

export const updateLightDiligence = async (id, data) => {

    return await API.put(`/api/v1/tasks/light-diligence/${id}/status`, data);
  }

export const getFileDownload = async (filename) => {
  return await API.get(`/api/v1/buyers/download/${filename}`, { responseType: 'blob' });
}

export const createScenario = async (data) => {
  return await API.post(`/api/v1/scenarios/create/`, data);
}

export const listScenarios = async (id) => {
  return await API.get(`/api/v1/scenarios/${id}/list/`);
}

export const deleteScenario = async (id) => {
  return await API.delete(`/api/v1/scenarios/${id}/delete/`);
}

export const updateScenario = async (id, data) => {
  return await API.put(`/api/v1/scenarios/${id}/update/`, data);
}

export const codeCreateSharedOpportunity = async (code) => {

  return await API.post(`/api/v1/buyers/shared_opportunity/code/${code}/`);
};