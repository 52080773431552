import * as React from 'react';
import Box from '@material-ui/core/Box/Box';
import Stepper from '@material-ui/core/Stepper/Stepper';
import Step from '@material-ui/core/Step/Step';
import StepLabel from '@material-ui/core/StepLabel/StepLabel';

const steps = [
  'Personal Details',
  'Work Experience',
  'Acquisition Experience',
  'Search Criteria',
  "Let's Talk Numbers"
];

const OnboardingBreadcrumbs = ({ currentStep }) => {

  return (
    <Box sx={{width: '80%', margin: 'auto'}}>
      <Stepper  activeStep={currentStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  )
}

export default OnboardingBreadcrumbs
