import "./Header.scss";

import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { CometChat } from "@cometchat-pro/chat";
import { NotificationsContext } from "../NotificationsProvider/NotificationsProvider";
import {
  Button,
  Fade,
  Link,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Menu,
  useMediaQuery,
} from "@material-ui/core";
import search from "../../assets/images/searchnormal1.svg";
import clipboardtext from "../../assets/images/clipboardtext.svg";
import down from "../../assets/images/down.svg";
import editProfile from "../../assets/images/editProfile.svg";
import AuthHelper from "shared/helpers/AuthHelper";
import vwLogo from "assets/images/Logo_NoIcon.png";
import notification from "../../assets/images/notification.svg";
import noNotification from "../../assets/images/no-notification.svg";
import MenuDrawer from "../MenuDrawer";
import {
  getProfileInfo,
  getStripe,
  stripePost,
} from "../../shared/apis/UserService";
import {
  connectionRequests,
  getMessages,
} from "../../shared/apis/BuyerService";
const { REACT_APP_CHAT_COMET_AUTH_KEY } = process.env;

const useStyles = makeStyles({
  container: {
    height: 95,
    position: "fixed",
    zIndex: 50,
    backgroundColor: "#fff",
  },
  containerMediaQuary: {
    height: 95,

    position: "relative",
    marginBottom: "-130px !important",
  },
  containerMediaQuaryIsopen: {
    height: "auto",
    position: "relative",
    marginBottom: "-60px !important",
  },
  topnav: {
    overflow: "hidden",
    backgroundColor: "#fff",
    position: "relative",
  },
  topnavOpen: {
    background: "#324f61",
  },
  topNavBarLink: {
    float: "none",
    display: "block",
    textAlign: "left",
    padding: "10px",
    "&:hover, &:focus": {
      backgroundColor: "#f5f5f5",
      "&a.MuiLink-root": {
        color: "#558BB9 !important",
      },
    },
  },
  menuOpen: {
    background: "#324f61",
    marginRight: "-1rem",
    padding: "4px",
    position: "absolute",
    right: "20px",
    top: "2px",
  },
  button: {
    color: "#324F61 !important",
    borderRadius: "4px !important",
    "&.MuiButton-outlined": {
      border: "2px solid #324F61",
    },
    fontFamily: "Inter-Bold !important",
    fontSize: "16px !important",
    padding: "2px",
    width: "auto !important",
  },
  avatar: {
    "&.MuiAvatar-colorDefault": {
      backgroundColor: "#324F61",
      width: "34px",
      height: "34px",
      cursor: "pointer",
    },
  },
  username: {
    color: " #324F61",
    letterSpacing: "0px",
    fontSize: "16px",
  },
  Menu: {
    "&.MuiMenu-paper": {
      border: "1px soild #8B8B8B",
    },
  },
  icon: {
    "&.MuiSvgIcon-root": {
      fontSize: "24px !important",
      "margin-left": "0px",
    },
  },
  active: {
    color: "#558BB9 !important",
  },
  customPadding: {
    paddingLeft: "10rem",
  },
});

const menuLink = [
  {
    id: 0,
    label: "Subscription",
    url: "/buyer-subscribe",
  },
  {
    id: 1,
    label: "Account settings",
    url: "/account-settings",
  },
  {
    id: 2,
    label: "Log out",
    url: "/logout",
  },
];

const resourceLink = [
  {
    id: 0,
    label: "Financing",
    url: "https://villagewellth.com/financing/",
  },
  {
    id: 1,
    label: "Blog",
    url: "https://villagewellth.com/blog/",
  },
  // {
  //   id: 2,
  //   label: "Micro Services",
  //   url: "https://blog.villagewellth.com/microservices/",
  // },
  {
    id: 3,
    label: "Masterclass",
    url: "https://villagewellth.com/blog/buying-a-business-masterclass/",
  },
];
const menuLinkSeller = [
  // {
  //   id: 0,
  //   label: "Subscription",
  //   url: "/seller-subscription",
  // },
  {
    id: 1,
    label: "Edit profile",
    url: "/editSellerProfile",
  },
  {
    id: 2,
    label: "Account settings",
    url: "/seller-settings",
  },

  {
    id: 3,
    label: "Log out",
    url: "/logout",
  },
];

function Header(props) {
  const authHelper = new AuthHelper();
  const isLoggedIn = authHelper.isLoggedIn();
  const role = authHelper.getRole();
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { history, isExternal } = props;

  const isActive = useMediaQuery("(max-width: 1150px)");
  const isMarketing = useMediaQuery("(max-width: 1350px)");
  const [user, setUser] = useState(null);
  const [dropDown, setDropDown] = useState(null);
  const [authKey, setAuthKey] = useState(null);

  const [loginToChat, setLoginToChat] = useState(false);
  const {
    unreadMessages,
    setUnreadMessages,
    userConnectionRequests,
    setUserConnectionRequests,
    fetchUnreadMessages,
    activeConversation,
    fetchConnectionRequests,
    userLoggedInChat,
    setUserLoggedInChat,
    stripeCustomer,
    setStripeCustomer,
    activeSubscription,
    setActiveSubscription,
  } = useContext(NotificationsContext);
// Gets billing portal link from backend for stripe customers
  const stripe = async () => {
    try {
      if (authHelper.isLoggedIn()) {
        let customer = { customer: stripeCustomer, role: role };

        const getStripe = await stripePost(customer);

        window.location = getStripe.data.url;
      }
    } catch (error) {
      console.log(error);
    }
  };

  let listenerID = user;

  CometChat.addMessageListener(
    listenerID,

    new CometChat.MessageListener({
      onTextMessageReceived: (textMessage) => {
        // eslint-disable-next-line
        if (textMessage.receiverId == activeConversation) {
          console.log(
            "Text message received successfully but not added because conversation is active",
            textMessage
          );
        } else {
          if (role === "seller") {
            const arr = textMessage.receiverId.split("_");
            const opportunity_id = arr[arr.length - 1];
            const opportunity = {
              opportunity: opportunity_id,
              conversation_id: textMessage.receiverId,
            };
            if (!unreadMessages.includes(opportunity)) {
              const newUnreadMessages = [...unreadMessages];
              newUnreadMessages.push(opportunity);
              setUnreadMessages(newUnreadMessages);
            } else {
              console.log(
                "Text message received successfully but not added",
                textMessage
              );
            }
          } else {
            if (!unreadMessages.includes(textMessage.receiverId)) {
              const newUnreadMessages = [...unreadMessages];
              newUnreadMessages.push(textMessage.receiverId);
              setUnreadMessages(newUnreadMessages);
            } else {
              console.log(
                "Text message received successfully but not added",
                textMessage
              );
            }
          }
        }
      },
      onMediaMessageReceived: (mediaMessage) => {
        // eslint-disable-next-line
        if (mediaMessage.receiverId == activeConversation) {
          console.log(
            "media message received successfully but not added because conversation is active",
            mediaMessage
          );
        } else {
          if (role === "seller") {
            const arr = mediaMessage.receiverId.split("_");
            const opportunity_id = arr[arr.length - 1];
            const opportunity = {
              opportunity: opportunity_id,
              conversation_id: mediaMessage.receiverId,
            };
            if (!unreadMessages.includes(opportunity)) {
              const newUnreadMessages = [...unreadMessages];
              newUnreadMessages.push(opportunity);
              setUnreadMessages(newUnreadMessages);
            } else {
              console.log(
                "media message received successfully but not added",
                mediaMessage
              );
            }
          } else {
            if (!unreadMessages.includes(mediaMessage.receiverId)) {
              const newUnreadMessages = [...unreadMessages];
              newUnreadMessages.push(mediaMessage.receiverId);
              setUnreadMessages(newUnreadMessages);
            } else {
              console.log(
                "media message received successfully but not added",
                mediaMessage
              );
            }
          }
        }
      },
      onCustomMessageReceived: (customMessage) => {
        // eslint-disable-next-line
        if (customMessage.receiverId == activeConversation) {
          console.log(
            "custom message received successfully but not added because conversation is active",
            customMessage
          );
        } else {
          if (role === "seller") {
            const arr = customMessage.receiverId.split("_");
            const opportunity_id = arr[arr.length - 1];
            const opportunity = {
              opportunity: opportunity_id,
              conversation_id: customMessage.receiverId,
            };
            if (!unreadMessages.includes(opportunity)) {
              const newUnreadMessages = [...unreadMessages];
              newUnreadMessages.push(opportunity);
              setUnreadMessages(newUnreadMessages);
            } else {
              console.log(
                "custom message received successfully but not added",
                customMessage
              );
            }
          } else {
            if (!unreadMessages.includes(customMessage.receiverId)) {
              const newUnreadMessages = [...unreadMessages];
              newUnreadMessages.push(customMessage.receiverId);
              setUnreadMessages(newUnreadMessages);
            } else {
              console.log(
                "custom message received successfully but not added",
                customMessage
              );
            }
          }
        }
      },
    })
  );

  const getUnreadMessages = async () => {
    try {
      let messages = await getMessages();
      if (messages.data) {
        setUnreadMessages(messages.data);
      } else {
        setUnreadMessages([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const wait = setTimeout(() => {
      getUnreadMessages();
    }, 3000);
    return () => clearTimeout(wait);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchUnreadMessages]);

  const fetchRequests = async () => {
    try {
      let requests;
      if (authHelper.isLoggedIn() && role === "buyer") {
        requests = await connectionRequests({ id: user });
        if (requests.data.results.length) {
          setUserConnectionRequests(true);
        } else {
          setUserConnectionRequests(false);
        }
      }
      if (authHelper.isLoggedIn() && role === "seller") {
        let params = {
          id: user,
          is_request_by_buyer: true
        };
        requests = await connectionRequests(params);
        if (requests.data.results.length) {
          console.log("requests", requests.data.results);
          setUserConnectionRequests(true);

        } else {
          setUserConnectionRequests(false);
        }
      }
    } catch (e) {
      setUserConnectionRequests(null);
    }
  };

  const onMenuClose = () => {
    setAnchorEl(null);
  };

  const onClickAvatar = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const dropAnchor = (e) => {
    setDropDown(e.currentTarget);
  };
  useEffect(() => {}, [dropDown, setDropDown]);
  useEffect(() => {}, [anchorEl, setAnchorEl]);

  const dropClose = () => {
    setDropDown(null);
  };

  const getData = async () => {
    try {
      if (authHelper.isLoggedIn()) {
        const userInfo = await getProfileInfo();
        setUser(userInfo.id);
        setAuthKey(userInfo.profile.chat_comet_auth_token);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // Check if user has subscription account created
  const fetchStripe = async () => {
    if (user) {
      try {
        const response = await getStripe(user);
        if (response.status === 200) {
          if (response.data.results[0]) {
            setStripeCustomer(response.data.results[0].stripe_customer_id);
            setActiveSubscription(response.data.results[0].is_active);
          }
        } else {
          setStripeCustomer(null);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleUpgrade = () => {
    if (role === "buyer") {
      history.push("/buyer-subscribe");
    } else {
      history.push("/seller-subscription");
    }
  };

  useEffect(() => {
    getData();
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (!stripeCustomer) {
      fetchStripe();
    }
    fetchRequests();

    //eslint-disable-next-line
  }, [user, fetchConnectionRequests]);

  let timerId;

  const startSessionTimer = () => {
    timerId = setTimeout(logoutUser, 10 * 60 * 1000); // 10 minutes
  };

  const resetSessionTimer = () => {
    if (authKey) {
      clearTimeout(timerId);
      startSessionTimer();
      if (!userLoggedInChat) {
        console.log("clicked", userLoggedInChat);
        setLoginToChat(!loginToChat);
      }
    }
  };
// Logs out user from chat if inactive for 10 minutes and automatically logs back in if user is active
  useEffect(() => {
    document.addEventListener("click", resetSessionTimer);
    document.addEventListener("keypress", resetSessionTimer);

    return () => {
      document.removeEventListener("click", resetSessionTimer);
      document.removeEventListener("keypress", resetSessionTimer);
    };
    //eslint-disable-next-line
  }, [authKey, timerId, startSessionTimer, userLoggedInChat]);

  const logoutUser = () => {
    CometChat.logout();
    setUserLoggedInChat(false);
  };
// Login to chat component
  useEffect(() => {
    if (authKey) {
      const uid = String(user);
      CometChat.login(uid, REACT_APP_CHAT_COMET_AUTH_KEY).then(
        (user) => {
          console.log("Login Successful:", { user });
          setUserLoggedInChat(true);
          getUnreadMessages();
          startSessionTimer();
        },
        (error) => {
          console.log("Login failed with exception:", { error });
        }
      );
    }
    //eslint-disable-next-line
  }, [authKey, user, loginToChat]);

  const onListItemClick = (item) => {
    if (item.url === "/logout") {
      authHelper.logout(history);
    } else if (
      item.url === "/sellerProfile" &&
      authHelper.getRole() === "buyer"
    ) {
      history.push("/profile");
    } else if (item.url === "/buyer-subscribe" && stripeCustomer) {
      stripe();
    } else if (item.url === "/seller-subscription" && stripeCustomer) {
      stripe();
    } else {
      history.push(item.url);
    }
    setAnchorEl(null);
  };

  const onResourceClick = (item) => {
    window.open(item.url, "_blank");
    setDropDown(null);
  };

  const isCredentialScreen =
    history.location.pathname !== "/login" &&
    history.location.pathname !== "/register";

  return (
    <div>
      { !props.showOnboarding && <MenuDrawer /> }

      <div
        className={`${`${classes.container} ${
          isOpen
            ? classes.containerMediaQuaryIsopen
            : classes.containerMediaQuary
        } w-100 d-flex justify-content-between align-items-center p-5 flex-row header-drop-shadow`}`}
      >
        <div
          className={`${
            isOpen && isActive
              ? `d-block`
              : "d-flex justify-content-between align-items-center header-content flex-row"
          }`}
        >
          <div className="mx-2 mx-md-4 ">
            <a
              href="https://www.villagewellth.com"
              className="font-weight-bold text-decoration-none"
            >
              <img
                alt="village wellth logo"
                src={vwLogo}
                className="logo-link"
              />
            </a>
          </div>

          {!isExternal && !props.showOnboarding && isCredentialScreen && (
            <>
              {!isLoggedIn ? (
                <div
                  className={`${
                    isMarketing ? (isOpen ? "" : "d-none") : "ml-4"
                  }`}
                >
                  <Link
                    className={`${
                      isOpen && isActive ? `${classes.topNavBarLink}` : ""
                    } header-link-color text-decoration-none header-link`}
                    href="/buyers"
                  >
                    Find Buyers
                  </Link>
                </div>
              ) : (
                <div
                  className={`${isActive ? (isOpen ? "" : "d-none") : "ml-4"}`}
                >
                  <Link
                    className={`${
                      isOpen && isActive ? `${classes.topNavBarLink}` : ""
                    } header-link-color text-decoration-none header-link`}
                    href="/buyers"
                  >
               <img alt="" src={search} className="mt-0 pb-1" />      
                    Find
                    Buyers
                  </Link>
                </div>
              )}
              {!isLoggedIn ? (
               <div
               className={`${
                 isMarketing ? (isOpen ? "" : "d-none") : "ml-4"
               }`}
             >
               <Link
                 className={`${
                   isOpen && isActive ? `${classes.topNavBarLink}` : ""
                 } header-link-color text-decoration-none header-link`}
                 href="/opportunities"
               >
                   
                 Find Deals
               </Link>
             </div>
              ) : (
                <div
                className={`${
                  isActive ? (isOpen ? "" : "d-none") : "ml-4"
                }`}
              >
                <Link
                  className={`${
                    isOpen && isActive ? `${classes.topNavBarLink}` : ""
                  } header-link-color text-decoration-none header-link`}
                  href="/opportunities"
                >
                     <img alt="" src={search} className="mt-0 pb-1" /> 
                  Find Deals
                </Link>
              </div>
              )}
              
              {role === "buyer" ? (
                <>
                  <div
                    className={`${
                      isActive ? (isOpen ? "" : "d-none") : "ml-4"
                    }`}
                  >
                    <Link
                      className={`${
                        isOpen && isActive ? `${classes.topNavBarLink}` : ""
                      } header-link-color text-decoration-none header-link`}
                      href="/profile"
                    >
                      <img alt="" src={editProfile} className="mt-0 pb-1" />{" "}
                      Profile
                    </Link>
                  </div>

                  <div
                    className={`${
                      isActive ? (isOpen ? "" : "d-none") : "ml-4"
                    }`}
                  >
                    <Link
                      className={`${
                        isOpen && isActive ? `${classes.topNavBarLink}` : ""
                      } header-link-color text-decoration-none header-link`}
                      href="/my-deals"
                    >
                      {userConnectionRequests || unreadMessages.length ? (
                        <img alt="" src={notification} className="mt-0 pb-1" />
                      ) : (
                        <img
                          alt=""
                          src={noNotification}
                          className="mt-0 pb-1"
                        />
                      )}
                      My Deals
                    </Link>
                  </div>
                </>
              ) : (
                <div></div>
              )}
              {!isLoggedIn ? (
                <div></div>
              ) : (
                <div>
                  {role === "seller" && (
                    <div
                      className={`${
                        isActive ? (isOpen ? "" : "d-none") : "ml-4"
                      }`}
                    >
                      <Link
                        className={`${
                          isOpen && isActive ? `${classes.topNavBarLink}` : ""
                        } header-link-color text-decoration-none header-link`}
                        href="/seller-deals"
                      >
                        {unreadMessages.length || userConnectionRequests ? (
                          <img
                            alt=""
                            src={notification}
                            className="mt-0 pb-1"
                          />
                        ) : (
                          <img
                            alt=""
                            src={noNotification}
                            className="mt-0 pb-1"
                          />
                        )}
                        My Deals
                      </Link>
                    </div>
                  )}
                </div>
              )}
              {!isLoggedIn ? (
                <div
                  className={`${
                    isMarketing ? (isOpen ? "" : "d-none") : "ml-4 mr-3"
                  }`}
                >
                  <Link
                    className={`${
                      isOpen && isActive ? `${classes.topNavBarLink}` : ""
                    } header-link-color text-decoration-none header-link row`}
                  >
                    <img
                      alt=""
                      src={clipboardtext}
                      className="mt-0 pb-1 pointer"
                    />{" "}
                    <div onClick={dropAnchor} className="pointer">
                      Resources
                    </div>
                    <img
                      alt=""
                      src={down}
                      className="pointer"
                      onClick={dropAnchor}
                    />
                    <Menu
                      id="simple-menu"
                      className={`user-menu ${classes.Menu}`}
                      elevation={0}
                      getContentAnchorEl={null}
                      anchorEl={dropDown}
                      keepMounted={true}
                      autoFocus={true}
                      open={Boolean(dropDown)}
                      onClose={dropClose}
                      transformOrigin={{ horizontal: "right", vertical: "top" }}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                      TransitionComponent={Fade}
                    >
                      <List dense={true}>
                        {resourceLink.map((value, index) => (
                          <React.Fragment key={index}>
                            {(!props.showOnboarding || value.id === 2) && (
                              <ListItem
                                key={value.id}
                                button
                                onClick={() => onResourceClick(value)}
                              >
                                <ListItemText primary={value.label} />
                              </ListItem>
                            )}
                          </React.Fragment>
                        ))}
                      </List>
                    </Menu>
                  </Link>
                </div>
              ) : (
                <div
                  className={`${
                    isActive ? (isOpen ? "" : "d-none") : "ml-4 mr-3"
                  }`}
                >
                  <Link
                    className={`${
                      isOpen && isActive ? `${classes.topNavBarLink}` : ""
                    } header-link-color text-decoration-none header-link row`}
                  >
                    <img
                      alt=""
                      src={clipboardtext}
                      className="mt-0 pb-1 pointer"
                    />{" "}
                    <div onClick={dropAnchor} className="pointer">
                      Resources
                    </div>
                    <img
                      alt=""
                      src={down}
                      className="pointer"
                      onClick={dropAnchor}
                    />
                    <Menu
                      id="simple-menu"
                      className={`user-menu ${classes.Menu}`}
                      elevation={0}
                      getContentAnchorEl={null}
                      anchorEl={dropDown}
                      keepMounted={true}
                      autoFocus={true}
                      open={Boolean(dropDown)}
                      onClose={dropClose}
                      transformOrigin={{ horizontal: "right", vertical: "top" }}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                      TransitionComponent={Fade}
                    >
                      <List dense={true}>
                        {resourceLink.map((value, index) => (
                          <React.Fragment key={index}>
                            {(!props.showOnboarding || value.id === 2) && (
                              <ListItem
                                key={value.id}
                                button
                                onClick={() => onResourceClick(value)}
                              >
                                <ListItemText primary={value.label} />
                              </ListItem>
                            )}
                          </React.Fragment>
                        ))}
                      </List>
                    </Menu>
                  </Link>
                </div>
              )}
            </>
          )}
        </div>

        <div
          className={`${
            !isLoggedIn
              ? isActive
                ? isOpen
                  ? classes.topnavOpen
                  : classes.topnav
                : "d-flex justify-content-end align-items-center log-in header-content flex-md-row flex-column "
              : "d-flex justify-content-end align-items-center log-in header-content flex-md-row flex-column "
          }`}
        >
          {isLoggedIn && !!role && !isExternal && (
            <div className="d-md-block"></div>
          )}
          {isLoggedIn && !isExternal ? (
            <>
              <div className="d-flex flex-row justify-content-center align-items-center">
                {role === 'buyer' && !activeSubscription && !isActive && !props.showOnboarding && (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className=" mr-4"
                    onClick={() => {
                      handleUpgrade();
                    }}
                  >
                    Upgrade
                  </Button>
                )}
                <p
                  className={`fs-2 font-regular mx-2 mb-0 ${
                    isActive ? "d-none" : "d-block"
                  } ${classes.username}`}
                >
                  {JSON.parse(window.localStorage.getItem("userDetails"))
                    .first_name ||
                    JSON.parse(window.localStorage.getItem("userDetails"))
                      .email}
                  <img
                    alt=""
                    src={down}
                    className="mx-1 pb-1"
                    onClick={onClickAvatar}
                  />
                </p>
              </div>
              <Menu
                id="simple-menu"
                className={`user-menu ${classes.Menu}`}
                elevation={0}
                getContentAnchorEl={null}
                anchorEl={anchorEl}
                keepMounted={true}
                autoFocus={true}
                open={Boolean(anchorEl)}
                onClose={onMenuClose}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                TransitionComponent={Fade}
              >
                {role === "buyer" ? (
                  <List dense={true}>
                    {menuLink.map((value, index) => (
                      <React.Fragment key={index}>
                        {(!props.showOnboarding || value.id === 2) && (
                          <ListItem
                            key={value.id}
                            button
                            onClick={() => onListItemClick(value)}
                          >
                            <ListItemText primary={value.label} />
                          </ListItem>
                        )}
                      </React.Fragment>
                    ))}
                  </List>
                ) : (
                  <List dense={true}>
                    {menuLinkSeller.map((value, index) => (
                      <React.Fragment key={index}>
                        {(!props.showOnboarding || value.id === 2) && (
                          <ListItem
                            key={value.id}
                            button
                            onClick={() => onListItemClick(value)}
                          >
                            <ListItemText primary={value.label} />
                          </ListItem>
                        )}
                      </React.Fragment>
                    ))}
                  </List>
                )}
              </Menu>
            </>
          ) : (
            <>
              {!isMarketing ? (
                <div>
                  {!isExternal && isCredentialScreen && (
                    <div className="d-flex justify-content-center align-items-center ">
                      <Link
                        className={` font-bold mx-4 ${
                          isActive
                            ? isOpen
                              ? `${classes.topNavBarLink} header-link-color`
                              : ""
                            : "text-decoration-none pr-4 font-weight-bold header-link"
                        }`}
                        href="/login"
                      >
                        Log in
                      </Link>

                      {isOpen && isActive ? (
                        <Link
                          component={Link}
                          href="/register"
                          className={`${classes.button} ${
                            isActive
                              ? isOpen
                                ? `${classes.topNavBarLink}  header-link-color`
                                : ""
                              : "ml-3 mr-2 mr-md-4"
                          }`}
                          variant="outlined"
                        >
                          Create Account
                        </Link>
                      ) : (
                        <Button
                          component={Link}
                          href="/register"
                          className={`${isActive ? "" : "ml-3 mr-2 mr-md-4"} ${
                            classes.button
                          } `}
                          variant="outlined"
                        >
                          Create Account
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div></div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default withRouter(Header);
