import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { CircularProgress, FormControlLabel, MenuItem, Radio, RadioGroup } from "@material-ui/core"
import { Button} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';
import '../shared/stepForm.scss';
import "./index.scss"
import {
  BackButton, BreadcrumbsClass, ButtonContainer,
  CenterNextButton, EmptyDiv, FormContainerClass, LabelClass,
  NextButton, OnboardingStepContainerClass, TitleClass
} from "../shared/BootStrapStyling";
import { isEmpty } from 'lodash';
import OnboardingBreadcrumbs from "../shared/OnboardingBreadcrumbs";
import SingleSelect from "../shared/SingleSelect";
import { ErrorMessage } from '@hookform/error-message';
import { industry1 } from '../../../shared/apis/OnboardingService'; 


const StepFour = ({ industries, onSubmit, defaultValue, onBack }) => {
  const { handleSubmit, control, errors, watch, clearErrors, setValue } = useForm({ mode: 'onBlur', defaultValues: {
    businessesAcquiredCode: defaultValue?.businesses_acquired_code ?? null,
    businessesSoldCode: defaultValue?.businesses_sold_code ?? null,
    lastAcquisitionIndustryId: defaultValue?.last_acquisition_industry_id ?? '',
    lastSaleIndustryId: defaultValue?.last_sale_industry_id ?? '',
  }})
  
  const businessesAcquired = watch('businessesAcquiredCode');
  const businessesSold = watch('businessesSoldCode');

  const IsSubmitted = useRef(false);

  const [industriesOptions, setIndustriesOptions] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    setIndustriesOptions(industries ?? [])
  },[industries])

  useEffect(() => {
      if(!businessesAcquired) {
        clearErrors('lastAcquisitionIndustryId')
        setValue('lastAcquisitionIndustryId', '')
      }
      if(!businessesSold) {
        clearErrors('lastSaleIndustryId')
        setValue('lastSaleIndustryId', '')
      }
  }, [businessesAcquired, clearErrors, businessesSold, setValue])

  const onSubmitStepFour = async (data) => {
    const updatedValues = {
      ...defaultValue,
      businesses_acquired_code: data.businessesAcquiredCode,
      businesses_sold_code: data.businessesSoldCode,
      last_acquisition_industry_id: data.lastAcquisitionIndustryId ? data.lastAcquisitionIndustryId : null,
      last_sale_industry_id: data.lastSaleIndustryId ? data.lastSaleIndustryId : null,
    }
    if (IsSubmitted.current) {
      setIsSubmitting(true)
      try {
        await industry1(updatedValues)
        setIsSubmitting(false)
        onSubmit(updatedValues);
      } catch (error) {
        setIsSubmitting(false)
      }
    } else {
      onBack(updatedValues)
    }
  };
  const onClickBack = () => {
    IsSubmitted.current = false;
  };

  const onClickSubmit = () => {
    IsSubmitted.current = true;
  };

  return (
    <div className={`${OnboardingStepContainerClass}`}>
      <div className={`${BreadcrumbsClass}`}><OnboardingBreadcrumbs currentStep={2} /></div>
      <form
        id="stepFour"
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit(onSubmitStepFour)}
        className={`${FormContainerClass}`}
      >
        <div>
          <h1 className={`${TitleClass}`}>
            Acquisition Experience
          </h1>
        </div>
        <div className='w-100 pt-3'>
          <div>
            <label className={`${LabelClass}`}>How many businesses have you acquired?</label>
            <Controller
              name="businessesAcquiredCode"
              control={control}
              rules={{ required: "Count of acquired businesses is required" }}
              render={({ onChange, value, ref }) => (
                <RadioGroup
                  name="businessesAcquiredCode"
                  row
                  aria-label="businessesAcquiredCode"
                  onChange={(e) => onChange(Number(e.target.value))}
                  value={value}
                  inputRef={ref}
                >
                  <FormControlLabel
                    value={0}
                    control={<Radio color="primary" />}
                    label="Zero"
                  />
                  <FormControlLabel
                    value={1}
                    control={<Radio color="primary" />}
                    label="1-2"
                  />
                  <FormControlLabel
                    value={2}
                    control={<Radio color="primary" />}
                    label="2+"
                  />
                </RadioGroup>
              )}
            />
            <ErrorMessage
              className="error-message"
              errors={errors}
              name="businessesAcquiredCode"
              as="p"
            />
          </div>
          <div className='pb-4 w-100'>
            <label className={`${LabelClass}`}>Industry of last acquisition</label>
            <Controller
              name="lastAcquisitionIndustryId"
              control={control}
              rules={{ required: {
                value: businessesAcquired && !isEmpty(industriesOptions),
                message: "Last acquisition industry is required"
              } }}
              render={({ onChange, value, ref }) => (
                <SingleSelect
                  name="lastAcquisitionIndustryId"
                  placeholder='Select Industry'
                  disabled={isEmpty(industriesOptions) || !businessesAcquired}
                  selectedValue={value}
                  setSelectedValue={onChange}
                  inputRef={ref}
                >
                  {industriesOptions?.map(industry => (
                    <MenuItem value={industry.id} key={industry.name + industry.id}>
                      {industry.name}
                    </MenuItem>
                  ))}
                </SingleSelect>
              )}
            />
            <ErrorMessage
              className="error-message mt-2"
              errors={errors}
              name="lastAcquisitionIndustryId"
              as="p"
            />
          </div>
        </div>
        <div>
          <div>
            <label className={`${LabelClass}`}>How many businesses have you sold?</label>
            <Controller
              name="businessesSoldCode"
              control={control}
              rules={{ required: "Count of sold businesses is required" }}
              render={({ onChange, value, ref }) => (
                <RadioGroup
                  name="businessesSoldCode"
                  row
                  aria-label="businessesSoldCode"
                  onChange={(e) => onChange(Number(e.target.value))}
                  value={value}
                  inputRef={ref}
                >
                  <FormControlLabel
                    value={0}
                    control={<Radio color="primary" />}
                    label="Zero"
                  />
                  <FormControlLabel
                    value={1}
                    control={<Radio color="primary" />}
                    label="1-2"
                  />
                  <FormControlLabel
                    value={2}
                    control={<Radio color="primary" />}
                    label="2+"
                  />
                </RadioGroup>
              )}
            />
            <ErrorMessage
              className="error-message"
              errors={errors}
              name="businessesSoldCode"
              as="p"
            />
          </div>
          <div className='pb-4'>
            <label className={`${LabelClass}`}>Industry of last sale</label>
            <Controller
              name="lastSaleIndustryId"
              control={control}
              rules={{ required: {
                value: businessesSold && !isEmpty(industriesOptions),
                message: "Industry selection for last sale is required"
              } }}
              render={({ onChange, value, ref }) => (
                <SingleSelect
                  name="lastSaleIndustryId"
                  placeholder='Select Industry'
                  disabled={isEmpty(industriesOptions) || !businessesSold}
                  selectedValue={value}
                  setSelectedValue={onChange}
                  inputRef={ref}
                >
                  {industriesOptions?.map(industry => (
                    <MenuItem value={industry.id} key={industry.name + industry.id}>
                      {industry.name}
                    </MenuItem>
                  ))}
                </SingleSelect>
              )}
            />
            <ErrorMessage
              className="error-message mt-2"
              errors={errors}
              name="lastSaleIndustryId"
              as="p"
            />
          </div>
        </div>
        <div className={`${ButtonContainer}`}>
          <div>
            <Button startIcon={<ArrowBackIcon />} type='submit' onClick={onClickBack}
                    className={`${BackButton}`}>
              {'Previous'}
            </Button>
          </div>
          <div className={`${CenterNextButton}`}>
            <Button 
              type='submit' 
              onClick={onClickSubmit}  
              className={`${NextButton}`}
              disabled={isSubmitting}
            >
              { isSubmitting ? <CircularProgress color="secondaryTextColor" size="1.9rem" /> : "Next" }
            </Button>
          </div>
          <div className={`${EmptyDiv}`}>{/*this element completes the three column layout and allows the 'next' button to be centered*/}</div>
        </div>
      </form>
    </div>
  );
};

StepFour.defaultProps = {};

StepFour.propTypes = {};

export default StepFour;
