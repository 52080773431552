import React from 'react';
import {Route, Redirect} from 'react-router-dom';

import AuthHelper from 'shared/helpers/AuthHelper';
import Header from "components/Header";
import Footer from "components/Footer";
import KeyIcon from 'assets/images/key1.svg';

import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
    p: {borderRadius: "25px",
    boxShadow: "2px 4px 8px 2px rgba(1, 0, 0, 0.2)"
    }
});
const AccountLayout = ({component: Component, enableRedirect, ...rest}) => {
    const classes = useStyles();
    const authHelper = new AuthHelper();
    let isLoggedIn = authHelper.isLoggedIn();

    return (
        <Route {...rest} render={matchProps => {
            if (enableRedirect && isLoggedIn && authHelper.getRole()) {
                return <Redirect to={{pathname: '/dashboard', state: {from: matchProps.location}}}/>
            }
            return (
                <div className="c-account-layout">
                    <Header showIcons={false}/>
                    <main className="c-layout__main c-account-layout__main container-fluid">
                        <div className="row flex-column-reverse flex-md-row border-top">
                            <div className="register col-12 col-md-5 pr-0 justify-content-center align-items-center">
                                <Component {...rest} {...matchProps} />
                            </div>
                            <div className=" col-12 col-md-7 border-left pl-0 pt-0 pr-5 pb-5 pt-5 login-testimonials">
                                <img src={KeyIcon} alt="key" className="w-100" />
                                <p className={`position-relative float-right mt-3 w-75 u-bg-gray px-5 py-3 box-text ${classes.p}`}>
                                <span className="font-weight-bold">Verified buyers testimonials</span> <br/>
                                "Finally, a place to begin my journey and call home. Love the dashboard setup" <br/>
                                "One stop shop where I can enter my information and share it"<br/>
                                "Saves me time to find companies I am interested in.... deals come to ME!"
                                </p>
                            </div>
                        </div>
                    </main>
                    <Footer/>
                </div>
            )
        }}/>
    );
};

export default AccountLayout;
