import React, { useEffect } from "react";
import { Route, Redirect } from 'react-router-dom';

import AuthHelper from 'shared/helpers/AuthHelper';
import Header from "components/Header";
import Footer from "components/Footer";
import { getProfileInfo } from "../../shared/apis/UserService";
import {withRouter} from "react-router-dom";

const ProfileLayout = ({component: Component, isExternal, enableRedirect, ...rest}) => {
    const authHelper = new AuthHelper();
    let isLoggedIn = authHelper.isLoggedIn();
    useEffect(() => {
        if (isLoggedIn) {
            checkIfAllDataExist();
        }
        else {
            rest.history.push('/login');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkIfAllDataExist = async () => {
      try {
        const data = await getProfileInfo();
        const profile = data.profile;
        //check is necessary to show onboarding
        if (profile.role === 'seller' || profile.role === 'Sr') {
        } else if (!data.first_name || !data.last_name || !profile.industries_details.length || !profile.user_industries.length || !profile.expertise || !profile.buyer_type
          || !profile.total_assets_available || !profile.target_company_earnings ||
          !profile.country || !profile.bio || !profile.looking_for_locations || !profile.looking_for_locations.length
        ) {
          rest.history.push('/dashboard');
        }
      } catch (e) {
      }
    }

    return (
        <Route {...rest} render={matchProps => {
            if (enableRedirect && isLoggedIn) {
                return <Redirect to={{pathname: '/dashboard', state: {from: matchProps.location}}}/>
            }
            return (
                <div className="c-default-layout">
                    <Header showIcons={false} isExternal={isExternal} />
        
                    <main className={`profile-layout__main c-default-layout__main  `}>
                        <Component {...matchProps} />
                    </main>
                    <Footer/>
                </div>
            )
        }} />
    )
};

export default withRouter(ProfileLayout);
