import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Header from "components/Header";
import Footer from "components/Footer";
import AuthHelper from "shared/helpers/AuthHelper";

const SellerLayout = ({ component: Component, ...rest }) => {
    const authHelper = new AuthHelper();
    let isLoggedIn = authHelper.isLoggedIn();
    return (
    <Route {...rest} render={matchProps => {
      if (!isLoggedIn) {
        // not logged in so redirect to login page with the return url
        return <Redirect to={{ pathname: '/login', state: { from: matchProps.location } }} />
      } else if (authHelper.getRole() === 'buyer') {
          return <Redirect to={{ pathname: '/buyers', state: { from: matchProps.location } }} />
      }
      // authorised so return component
      return (
          <div className="c-private-layout">
              <Header isLoggedIn={isLoggedIn} showIcons={true} />
              {/* <SideBar userRole={userRole}></SideBar> */}
              <main className="c-layout__main c-private-layout__main container-fluid">
                <Component {...rest} {...matchProps}/>
              </main>
              <Footer />
          </div>
      )
    }} />
  );
};

export default SellerLayout;
