import API from './ApiBase';

export const register = (payload) => {
  return API.post('/api/v1/user/', payload);
};

export const login = (payload) => {
  return API.post('/api/v1/user/login/', payload);
};

export const logout = () => {
  return API.post('/api/v1/user/logout/');
};

export const updateUser = (userId, payload) => {
  return API.put(`/api/v1/users/${userId}/`, payload);
};

export const updateUserPassword = (userId, payload) => {
  return API.put(`/api/v1/users/${userId}/password/`, payload);
};

export const forgetPassword = (payload) => {
  return API.post(`/api/v1/user/password_reset/`, payload);
};

export const resetPassword = (payload, token) => {
  return API.post(`/api/v1/user/password_reset/confirm/?token${token}`, payload);
};
